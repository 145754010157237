export const getPromotionProducts = `
  query getPromotionProducts($promotionCode: String!, $languageCode: String) {
    promotionPricingPlans(promotionCode: $promotionCode, languageCode: $languageCode) {
      trialDays
      sortOrder
      price
      interval
      description
      currency
      name
      intervalUnit
      entity
      offerHandle
      productHandle
      trialPrice
      isRecurring
      finalPrice
      pricePointId
    }
  }
`
