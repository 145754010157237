export const CURRENCIES = {
  EUR: "€", // Euro
  SGD: "SGD", // Singapore Dollar
  CAD: "CAD", // Canadian Dollar
  AUD: "AUD", // Australian Dollar
  NZD: "NZD", // New Zealand Dollar
  AED: "AED", // United Arab Emirates Dirham
  USD: "USD", // United States Dollar
  GBP: "£", // Great British Pound
  INR: "₹", // Indian Rupee
  MYR: "RM", // Malaysian Ringgit
  HKD: "HKD", // Hong Kong Dollar
  BRL: "BRL", // Brazilian real
  ZAR: "ZAR", // South African Rand
  CHF: "CHF", // Swiss Franc
  JPY: "¥", // Japanese yen
}
