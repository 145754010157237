import Amplify, { Auth } from "aws-amplify"
import awsconfig from "../configs/aws-exports"
import { authStorage } from "../services/amplifyStorage"
// import { cookiesStorageConfig } from "../configs/cognito-config"

const { GATSBY_RT_26_07_2022_SYNC_SESSION } = process.env

export const client = () =>
  Amplify.configure({
    ...awsconfig,
    ...(GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && {
      Auth: {
        storage: authStorage,
      },
    }),
    API: {
      graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
      graphql_endpoint_iam_region: process.env.GATSBY_AWS_COGNITO_REGION,
      graphql_endpoint: process.env.GATSBY_GRAPHQL_ENDPOINT,
    },
    aws_user_pools_web_client_id:
      process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
    // Keep session in cookies otherwise Auth session will be flew away after refreshed
    // ...cookiesStorageConfig,
  })

export const publicClient = () =>
  Amplify.configure({
    ...awsconfig,
    ...(GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && {
      Auth: {
        storage: authStorage,
      },
    }),
    aws_user_pools_web_client_id:
      process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
    API: {
      graphql_endpoint_iam_region: process.env.GATSBY_AWS_COGNITO_REGION,
      graphql_endpoint: process.env.GATSBY_GRAPHQL_PUBLIC_ENDPOINT,
    },
  })

export const cognitoGetAuth = async callback => {
  try {
    // config AWS Amplify
    client()

    const user = await Auth.currentAuthenticatedUser()
    callback(user.attributes)
  } catch {
    return
  }
}
