import { isBrowser } from "../utils/localstorage"
import { authStorage } from "../services/amplifyStorage"

const { GATSBY_RT_26_07_2022_SYNC_SESSION } = process.env

export const cookiesStorageConfig = {
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: process.env.GATSBY_SITE_DOMAIN,
    // OPTIONAL - Cookie path
    path: "/",
    // OPTIONAL - Cookie expiration in days
    expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: isBrowser && window.location.protocol === "https",
  },
}

export const cognitoConfig = {
  Auth: {
    region: process.env.GATSBY_AWS_COGNITO_REGION,
    userPoolId: process.env.GATSBY_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: process.env.GATSBY_AUTH_FLOW_TYPE,
    ...(GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && {
      storage: authStorage,
    }),
  },
  // ...cookiesStorageConfig,
}
