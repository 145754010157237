// TailwindCSS Base
import "./src/styles/tw-base.css"

// Font Awesome
import "./src/assets/fonts/fontawesome/css/font-awesome.min.css"

// Component styles
import "./src/styles/spinner.css"
import "./src/styles/buttons.css"
import "./src/styles/checkbox.css"
import "./src/styles/textbox.css"
import "./src/styles/dropdown.css"
import "./src/styles/icons.css"
import "./src/styles/form.css"
import "./src/styles/subscription.css"
import "./src/styles/video.css"
import "./src/styles/testimonial.css"
import "./src/styles/toggle-button.css"
import "./src/styles/notification-toast.css"
import "./src/styles/layout.css"
import "./src/styles/modal.css"
import "./src/styles/accordion.css"
import "./src/styles/progress-steps.css"
import "./src/styles/progress-bar.css"
import "./src/styles/radio.css"
import "./src/styles/textarea.css"
import "./src/styles/tooltip.css"
import "./src/styles/cancellation-modals.css"
import "./src/styles/getting-started.css"
import "./src/styles/faq.css"
import "./src/styles/profile-dropdown.css"
import "./src/styles/header.css"
import "./src/styles/footer.css"
import "./src/styles/create-account.css"
import "./src/styles/sign-in.css"
import "./src/styles/redeem.css"
import "./src/styles/cookie-policy.css"
import "./src/styles/terms-of-use.css"
import "./src/styles/privacy-policy.css"
import "./src/styles/marketing-page.css"
import "./src/styles/challenges.css"
import "./src/styles/date-picker.css"
import "./src/styles/chargify-dropin.css"
import "./src/styles/reseller.css"

// TailwindCSS Utilities
import "./src/styles/tw-utilities.css"
