export const getProductById = `
  query getProduct($productId: String!, $countryCode: String) {
    getProduct(product_handle: $productId, country_code: $countryCode) {
      product_handle
      name
      description
      currency
      product_price_point {
        price_in_cents
        interval
        interval_unit
        trial_price_in_cents
        trial_interval
        trial_interval_unit
      }
    }
  }
`
