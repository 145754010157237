import { isEmpty } from "./utilities"

export const USER_INFO_KEY = "user-info"
export const FORGOT_PWD_KEY = "forgot-pwd"
export const NEW_SUBSCRIPTION_KEY = "new-subscription"
export const CREATE_ACCOUNT_STATUS = "create-account-status"
export const USER_COUNTRY = "user-country"
export const USER_POSTAL_CODE = "user-postal-code"
export const CURRENT_LANG = "lang"
export const CURRENT_COUNTRY = "current-country"
export const RECAPTCHA_RENDERED = "recaptcha-rendered"
export const RECAPTCHA_TOKEN = "recaptcha-token"
export const LOOKUP_ADDRESS_TARGET = "lookup-address-target"
export const MEMBERSHIP_INFO = "membership-info"
export const AUTOMATION_RECAPTCHA = "automation-recaptcha"
export const SKIP_3DS = "skip-3ds"
export const IS_MAGENTO = "is-magento"
export const SECURITY_CHARGIFY_TOKEN = "security_chargify_token"
export const ROLLOVER_PREFERENCE = "rollover_preference"
export const RESELLER_ACCESS_ID = "reseller_access_id"
export const IS_FEDERATED_ACCOUNT = "is_federated_account"
export const IS_REDIRECT_TO_SIGNUP = "is_redirect_to_signup"
export const JWT_TOKEN = "jwtToken"
export const IS_MANUAL_LOGIN = "is_manual_login"
export const IS_MEMBER_PROMO_DISPLAYED = "is_member_promo_displayed"

export const isBrowser = typeof window !== "undefined"

export const getLocalStorage = (key = USER_INFO_KEY) => {
  return isBrowser && window.localStorage
    ? JSON.parse(window.localStorage.getItem(key)) || {}
    : {}
}

export const redirectTo = async (url, params = "") => {
  if (isBrowser && window.location) {
    window.location.href = url + params
  }
}

export const setLocalStorage = (key = USER_INFO_KEY, data = {}) => {
  isBrowser &&
    window.localStorage &&
    window.localStorage.setItem(key, JSON.stringify(data))
}

export const deleteLocalStorage = (key = USER_INFO_KEY) => {
  isBrowser && window.localStorage && window.localStorage.removeItem(key)
}

export const deleteSessionStorage = (key = USER_INFO_KEY) => {
  isBrowser && window.sessionStorage && window.sessionStorage.removeItem(key)
}

export const clearLocalStorage = () => {
  const currentLang = getLocalStorage(CURRENT_LANG)
  isBrowser && window.localStorage && window.localStorage.clear()
  setLocalStorage(CURRENT_LANG, currentLang)
}

export const setSessionStorage = (key = CURRENT_COUNTRY, data = {}) => {
  isBrowser &&
    window.sessionStorage &&
    window.sessionStorage.setItem(
      key,
      JSON.stringify(
        key === CURRENT_COUNTRY && data ? data.toLowerCase() : data
      )
    )
}

export const getSessionStorage = (key = CURRENT_COUNTRY) => {
  return isBrowser && window.sessionStorage
    ? JSON.parse(window.sessionStorage.getItem(key)) || ""
    : ""
}

// Get automation reCaptcha from cookie
export const getAutomationRecaptcha = () => {
  const automationRecaptcha = getLocalStorage(AUTOMATION_RECAPTCHA)

  if (isEmpty(automationRecaptcha)) {
    return null
  }
  return automationRecaptcha
}
