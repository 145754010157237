/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useSiteMetadata } from "../../hooks/site-metadata"
import * as Sentry from "@sentry/browser"
import { ExtraErrorData } from "@sentry/integrations"

import {
  checkToRedirectCurrentLangPage,
  checkVisibleLanguageDropdownCountry,
  isEmpty,
} from "../../utils/utilities"
import { isProductionBuild, isBypassCaptureError } from "../../utils/sentry"
import { isValidRoute } from "../../utils/redirection"
import { ROUTES } from "../../constants/routes"

// CSS support IE11
import "../../styles/ie11-fix.css"
import {
  getSessionStorage,
  CURRENT_COUNTRY,
  USER_INFO_KEY,
  getLocalStorage,
} from "../../utils/localstorage"

// Only init Sentry for production build
if (isProductionBuild) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.ENVIRONMENT || "local",
    integrations: [new ExtraErrorData({ depth: 10 })],
    normalizeDepth: 11,
    ignoreErrors: ["not authenticated"],
    beforeSend(event, hint) {
      if (isBypassCaptureError(hint)) {
        return null
      }
      if (!event.user || (event.user && !event.user.email)) {
        const userInfo = getLocalStorage(USER_INFO_KEY)
        const userEmail =
          (userInfo.email
            ? userInfo.email
            : hint && hint.originalException && hint.originalException.email) ||
          ""
        event = {
          ...event,
          user: {
            // add user email to each event
            email: userEmail,
          },
        }
      }
      return event
    },
  })
}

const SEO = ({
  description,
  lang,
  meta,
  title,
  author,
  keywords,
  pathname,
  image: pageImage,
  ogType,
  ogTitle,
  ogDescription,
  twitterTitle,
  twitterDescription,
  twitterCard,
  scripts,
}) => {
  const siteMetadata = useSiteMetadata()
  const canonical = pathname
    ? `https://${process.env.GATSBY_SITE_DOMAIN}${pathname}`
    : null
  const image = pageImage ? pageImage.url : null
  // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
  // based on ip for cart process and non-logged in user
  // based on user's billing address for other pages and logged in user
  const isInCartProcess = pathname.includes(ROUTES().CREATE_ACCOUNT)
  const user = getLocalStorage(USER_INFO_KEY)
  const isLoggedIn = !isEmpty(user)
  const currentCountry =
    !isInCartProcess && isLoggedIn
      ? user.addressCountry
      : getSessionStorage(CURRENT_COUNTRY)

  useEffect(() => {
    // Check to redirect base on current language
    // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
    // only redirect to current lang in de, at
    if (
      isValidRoute(pathname) &&
      checkVisibleLanguageDropdownCountry(currentCountry)
    ) {
      checkToRedirectCurrentLangPage(lang)
    }
  }, [])
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      script={scripts}
      meta={[
        {
          name: `description`,
          content: description || siteMetadata.description,
        },
        {
          name: `keywords`,
          content: keywords || siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          name: `twitter:creator`,
          content: author || siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
      ]
        .concat(
          pageImage && pageImage.dimensions
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: pageImage.dimensions.width,
                },
                {
                  property: "og:image:height",
                  content: pageImage.dimensions.height,
                },
                {
                  property: "twitter:image",
                  content: image,
                },
                {
                  property: "twitter:image:width",
                  content: pageImage.dimensions.width,
                },
                {
                  property: "twitter:image:height",
                  content: pageImage.dimensions.height,
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: twitterCard,
                },
              ]
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  keywords: ``,
  pathname: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  keywords: PropTypes.string,
  pathname: PropTypes.string,
  ogType: PropTypes.string,
  twitterCard: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
  scripts: PropTypes.array,
}

export default React.memo(SEO)
