import React from "react"
import SEO from "../../src/components/SEO"

export const renderSEO = (pageContent, pathname, lang, scripts = []) => {
  const {
    page_title = {},
    page_description = {},
    page_keywords = {},
    page_image = {},
    page_author = {},
    page_og_title = {},
    page_og_description = {},
    page_twitter_title = {},
    page_twitter_description = {},
    page_twitter_card = {},
    page_og_type = {},
  } = pageContent || {}
  return (
    <SEO
      title={page_title.text || ""}
      description={page_description.text || ""}
      keywords={page_keywords.text || ""}
      lang={lang}
      pathname={pathname}
      image={page_image || ""}
      author={page_author.text || ""}
      ogType={page_og_type.text || ""}
      ogTitle={page_og_title.text || ""}
      ogDescription={page_og_description.text || ""}
      twitterTitle={page_twitter_title.text || ""}
      twitterDescription={page_twitter_description.text || ""}
      twitterCard={page_twitter_card.text || ""}
      scripts={scripts}
    />
  )
}
