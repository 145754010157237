const { GATSBY_GETTING_STARTED_URL: RECEIVER_URL } = process.env

const getKeyWithoutRandomString = key => {
  const keySplit = key.split(".")
  return `LMPlusAuthProvider.${keySplit[keySplit.length - 1]}`
}

const postMessageToReceiver = message => {
  const sessionReceiver = document.getElementById("session_receiver")

  if (!sessionReceiver) {
    console.warn("unable to find sessionReceiver")
    return
  }

  sessionReceiver.contentWindow.postMessage(message, RECEIVER_URL)
}

// slash is already included in env var
export const sessionReceiverURL = RECEIVER_URL
  ? `${RECEIVER_URL}syncSession`
  : undefined

export const authStorage = {
  setItem: (key, value) => {
    const formattedKey = getKeyWithoutRandomString(key)

    window.localStorage.setItem(formattedKey, value)

    postMessageToReceiver({ action: "setItem", key: formattedKey, value })

    return value
  },
  getItem: key => {
    const formattedKey = getKeyWithoutRandomString(key)

    return window.localStorage.getItem(formattedKey)
  },
  removeItem: key => {
    const formattedKey = getKeyWithoutRandomString(key)

    window.localStorage.removeItem(formattedKey)

    postMessageToReceiver({ action: "removeItem", key: formattedKey })
  },
  clear: () => {
    window.localStorage.clear()

    postMessageToReceiver({ action: "clear" })
  },
}
