import * as React from "react"
import isEqual from "lodash/isEqual"
import AccordionItem from "./AccordionItem"

type ItemClassNames = {|
  title?: string,
  icon?: string,
|}

type AccordionItemType = {|
  id: string,
  title: string | React.Node,
  children: React.Node,
|}

type Props = {|
  list: Array<AccordionItemType>,
  icon: string,
  testId?: string,
  classNames?: {
    wrapper?: string,
    item?: ItemClassNames,
  },
  isMultiple?: boolean,
  handleToggleFromOutside: () => void,
|}

const Accordion = ({
  list,
  testId = "",
  classNames = {
    wrapper: "",
    item: {
      title: "",
      icon: "",
      wrapper: "",
      accordion: "",
      selectedWrapper: "",
    },
  },
  icon,
  handleToggleFromOutside = () => {},
}: Props) => {
  const [selectedId, setSelectedId] = React.useState("")
  const { wrapper, selectedWrapper } = classNames.item || {}
  const accordionItemRefs = list.map(() => React.useRef(null))

  const handleToggleAccordion = React.useCallback(
    (isOpened, newId, newIndex, maxHeight) => {
      // Click again to close old question
      if (selectedId) {
        accordionItemRefs[
          list.findIndex(i => i.id === selectedId)
        ].current.firstChild.click()
      }

      setSelectedId(isOpened ? "" : newId)

      // Click to open new question
      accordionItemRefs[newIndex].current.firstChild.click()
      handleToggleFromOutside(maxHeight)
    }
  )

  return (
    <ul
      className={`w-full${classNames.wrapper || ""}`}
      data-cy={testId}
      data-testid={testId}
    >
      {list.map((item, index) => {
        const itemtestId = `${testId}-${index}`
        const isOpened = item.id === selectedId

        return (
          <li
            className={isOpened && selectedWrapper ? selectedWrapper : wrapper}
            key={itemtestId}
            data-cy={itemtestId}
            data-testid={itemtestId}
          >
            <AccordionItem
              onToggleFromOutside={maxHeight =>
                handleToggleAccordion(isOpened, item.id, index, maxHeight)
              }
              item={item}
              classNames={classNames.item}
              testId={itemtestId}
              icon={icon}
              innerRef={accordionItemRefs[index]}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default React.memo(Accordion, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.list, nextProps.list) &&
    isEqual(prevProps.isMultiple, nextProps.isMultiple)
  )
})
