import React, { useState } from "react"
import Popover from "../Popover"

type Props = {|
  options: Array<{
    value: string,
    label: string,
  }>,
  handleOnSelect: () => void | Promise<void>,
  currentValue: string,
  classNames?: Object,
  icon: React.Node,
  selectedIcon: React.Node,
  label: string,
  tabIndex?: number,
|}

const CustomSelect = ({
  options,
  handleOnSelect,
  currentValue,
  classNames,
  icon,
  label,
  selectedIcon,
  tabIndex,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOptionClick = value => {
    handleOnSelect(value)
    setIsExpanded(false)
  }

  const selectedOption = options.find(item => item.value === currentValue) || {}
  const { wrapper, list, listItem, selectedItem } = classNames

  return (
    <Popover togglePopover={setIsExpanded} className={`relative${wrapper}`}>
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className={`relative cursor-pointer${selectedItem}`}
        tabIndex={tabIndex} // add tabIndex to css works in focused state
      >
        {icon}
        {selectedOption.label}
      </div>
      {label && <label className={classNames.label}>{label}</label>}
      {isExpanded && (
        <ul
          className={`absolute cursor-pointer padding-2 overflow-hidden shadow-xs${list}`}
        >
          {options.map(item => {
            const value = item.value
            const isSelected = value === selectedOption.value
            return (
              <li
                key={value}
                onClick={() => handleOptionClick(value)}
                className={listItem}
              >
                {isSelected && selectedIcon}
                <span className={classNames.innerListItem}>{item.label}</span>
              </li>
            )
          })}
        </ul>
      )}
    </Popover>
  )
}

export default CustomSelect
