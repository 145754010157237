import React from "react"

type Props = {|
  rows: string,
  cols: string,
  id?: string,
  placeholder?: string,
  className?: string,
  handleOnChange?: () => void | Promise<void>,
  testId?: string,
|}
const TextArea = ({
  rows,
  cols,
  id = "",
  placeholder = "",
  className = "",
  handleOnChange = () => {},
  testId = "",
}: Props) => (
  <textarea
    rows={rows}
    cols={cols}
    id={id}
    placeholder={placeholder}
    className={className}
    onChange={handleOnChange}
    data-cy={testId}
  ></textarea>
)

export default TextArea
