// @flow
import React, { useState, useRef, useEffect } from "react"

type Props = {|
  offsetTop?: number,
  children?: () => void,
|}

const StickyElement = ({ offsetTop = 0, children }: Props) => {
  const [isSticky, setSticky] = useState(false)
  const stickyBoxRef = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScroll = () => {
    if (stickyBoxRef.current.getBoundingClientRect().top + offsetTop <= 0) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  return (
    <div
      ref={stickyBoxRef}
      data-testid={"StickyElementLanding"}
      className="w-full z-100"
    >
      {children({ isSticky: isSticky })}
    </div>
  )
}

export default StickyElement
