import { PARAMS, EVENT, LOGIN_STATUS } from "../constants/dataTracking"
import { getLocalStorage, isBrowser } from "./localstorage"
import { getConvertedPrice, getPlanPeriod } from "./product"

export const pushTracking = data => {
  window.dataLayer = window.dataLayer || []
  if (!isBrowser && !data) {
    return
  }
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    ...data,
  })
}

export const loginTracking = vimeoId => {
  pushTracking({
    event: EVENT.CUSTOMER_LOGIN,
    [PARAMS.CUSTOMER_LOGGED_IN_STATUS]: LOGIN_STATUS.LOGGED_IN,
    [PARAMS.VIMEO_ID]: vimeoId,
    [PARAMS.LOGIN_TYPE]: "email",
  })
}

export const logoutTracking = vimeoId => {
  const loginType = getLocalStorage("loginType") || "email"
  pushTracking({
    event: EVENT.CUSTOMER_LOGOUT,
    [PARAMS.CUSTOMER_LOGGED_IN_STATUS]: LOGIN_STATUS.LOGGED_IN,
    [PARAMS.VIMEO_ID]: vimeoId,
    [PARAMS.LOGIN_TYPE]: loginType,
  })
}

export const pageViewTracking = (userData, loginStatus) => {
  const { product_price_point, name, tier, currency } = userData?.product || {}

  pushTracking({
    event: EVENT.PAGE_VIEW,
    [PARAMS.PLAN_NAME]: name,
    [PARAMS.PLAN_TIER]: tier?.internal_tier_id,
    [PARAMS.PLAN_BILLING_FREQ]:
      product_price_point &&
      getPlanPeriod(
        product_price_point?.interval,
        product_price_point?.interval_unit
      ),
    [PARAMS.CURRENCY]: currency,
    [PARAMS.PLAN_PRICE]:
      product_price_point &&
      getConvertedPrice(
        product_price_point?.price_in_cents,
        currency
      )?.toString(),
    [PARAMS.CUSTOMER_LOGGED_IN_STATUS]: loginStatus,
    [PARAMS.VIMEO_ID]: userData?.vimeoUserId,
  })
}

export const formStartTracking = ({
  formName,
  planName,
  planTier,
  planBillingFreq,
}) => {
  pushTracking({
    event: EVENT.FORM_START,
    [PARAMS.FORM_NAME]: formName,
    [PARAMS.PLAN_NAME]: planName,
    [PARAMS.PLAN_TIER]: planTier,
    [PARAMS.PLAN_BILLING_FREQ]: planBillingFreq,
  })
}

export const formSubmitTracking = ({
  formName,
  planName,
  planTier,
  planBillingFreq,
}) => {
  pushTracking({
    event: EVENT.FORM_SUBMIT,
    [PARAMS.FORM_NAME]: formName,
    [PARAMS.PLAN_NAME]: planName,
    [PARAMS.PLAN_TIER]: planTier,
    [PARAMS.PLAN_BILLING_FREQ]: planBillingFreq,
  })
}

export const formErrorTracking = ({
  formName,
  formErrorName,
  planName,
  planTier,
  planBillingFreq,
}) => {
  pushTracking({
    event: EVENT.FORM_ERROR,
    [PARAMS.FORM_NAME]: formName,
    [PARAMS.FORM_ERROR_NAME]: formErrorName,
    [PARAMS.PLAN_NAME]: planName,
    [PARAMS.PLAN_TIER]: planTier,
    [PARAMS.PLAN_BILLING_FREQ]: planBillingFreq,
  })
}

export const customerDetailsUpdateTracking = vimeoId => {
  pushTracking({
    event: EVENT.CUSTOMER_DETAILS_UPDATE,
    [PARAMS.CUSTOMER_LOGGED_IN_STATUS]: LOGIN_STATUS.LOGGED_IN,
    [PARAMS.VIMEO_ID]: vimeoId,
  })
}
