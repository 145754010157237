import React from "react"

type Props = {|
  handleOnToggle?: () => void | Promise<void>,
  disabled?: Boolean,
  checked?: Boolean,
  classNames?: string,
|}

const ToggleButton = ({
  handleOnToggle = () => {},
  disabled = false,
  checked = false,
  classNames = "",
}: Props) => (
  <label className={`relative inline-block w-12 h-6 ${classNames}`}>
    <input
      type="checkbox"
      checked={checked}
      onChange={handleOnToggle}
      className="w-0 h-0 opacity-0"
      disabled={disabled}
    />
    <span
      className={`w-59 h-25 slider rounded-full absolute top-0 left-0 right-0 bottom-0 bg-gray-35 ${
        disabled ? "disabled" : "cursor-pointer"
      }`}
    >
      <span className="font-base-black text-gray-800 text-2xxs leading-16">
        {checked ? "ON" : "OFF"}
      </span>
    </span>
  </label>
)

export default ToggleButton
