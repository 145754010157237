// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
  aws_project_region: process.env.GATSBY_AWS_COGNITO_REGION,
  aws_cognito_region: process.env.GATSBY_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.GATSBY_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.GATSBY_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.GATSBY_AWS_COGNITO_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
}

export default awsconfig
