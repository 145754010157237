export default {
  layout: {
    sign_out_button: {
      type: "paragraph",
      text: "Sign out",
      spans: [],
    },
    sign_in_button: {
      type: "paragraph",
      text: "Sign in",
      spans: [],
    },
    logo: {
      dimensions: {
        width: 200,
        height: 48,
      },
      alt: "lesmills",
      copyright: null,
      url: "https://place-hold.it/200x48",
    },
    best_value: {
      type: "paragraph",
      text: "best value",
      spans: [],
    },
    body: [
      {
        items: ["home", "community", "plans", "My account"].map(item => ({
          label: {
            text: item,
            raw: [
              {
                type: "paragraph",
                text: item,
                spans: [
                  {
                    type: "hyperlink",
                    data: {},
                  },
                ],
              },
            ],
          },
        })),
      },
      {
        items: ["search", "subscribe", "sign in", "help"].map(item => ({
          label: {
            text: item,
            raw: [
              {
                type: "paragraph",
                text: item,
                spans: [
                  {
                    type: "hyperlink",
                    data: {},
                  },
                ],
              },
            ],
          },
        })),
      },
    ],
    body1: [
      {
        items: ["lesmills.com", "Terms of Use"].map(item => ({
          label: {
            text: item,
            raw: [
              {
                type: "paragraph",
                text: item,
                spans: [
                  {
                    type: "hyperlink",
                    data: {},
                  },
                ],
              },
            ],
          },
        })),
      },
    ],
  },
  units: {
    monthly_interval: {
      text: "Monthly",
    },
    daily_interval: {
      text: "Daily",
    },
    annual_interval: {
      text: "Annual",
    },
    annually_interval: {
      text: "Annually",
    },
    day: {
      text: "day",
    },
    day_plural: {
      text: "days",
    },
    day_singular: {
      text: "day",
    },
    month: {
      text: "month",
    },
    month_plural: {
      text: "months",
    },
    month_singular: {
      text: "month",
    },
    year: {
      text: "year",
    },
    year_plural: {
      text: "years",
    },
    year_singular: {
      text: "year",
    },
  },
  countryOptions: [
    { value: "GE", name: "Germany" },
    { value: "FR", name: "France" },
    { value: "NZ", name: "New Zealand" },
  ],
  promotionCode: {
    have_promo_code: [
      {
        type: "paragraph",
        text: "I have a promo code",
      },
    ],
    apply_promo_code_button_label: [
      {
        text: "Apply",
      },
    ],
    promo_code: [
      {
        type: "paragraph",
        text: "Promo code",
      },
    ],
    promo_code_applied: [
      {
        type: "paragraph",
        text: "Success! Promo code has been applied.",
      },
    ],
    promo_code_invalid: [
      {
        type: "paragraph",
        text:
          "That is not a valid promo code, please try again or try another promo code.",
      },
    ],
    promo_code_title: [
      {
        type: "heading2",
        text: "Enter Promo code",
      },
    ],
  },
  frequentlyAskedQuestions: [
    {
      answer: {
        raw: [
          {
            type: "paragraph",
            text:
              "If you want to use LMOD when travelling, please use one of our apps, as you will not be able to access the website from an unsupported country.",
            spans: [],
          },
        ],
      },
      question: {
        raw: [
          {
            type: "paragraph",
            text: "Do cookies need to be enabled on my computer?",
            spans: [],
          },
        ],
      },
      id: "q-1",
    },
  ],
  primicBody: [
    {
      type: "all_subscriptions",
      primary: {
        all_subscriptions_title: [
          {
            type: "heading2",
            text: "All subscriptions include",
          },
        ],
      },
      fields: [
        {
          subscription_description: [
            {
              type: "paragraph",
              text:
                "Workouts backed by research and science and tested to work.",
            },
          ],
        },
      ],
    },
  ],
  prismicCountries: [
    {
      country_code: {
        text: "nz",
      },
      country_name: {
        text: "New Zealand",
      },
    },
    {
      country_code: {
        text: "de",
      },
      country_name: {
        text: "Germany",
      },
    },
  ],
  questions: [
    {
      answer: [
        {
          text:
            "After entering your details, you will be given a 14-day trial.",
        },
      ],
      question: [
        {
          text: "How does the free trial work?",
        },
      ],
    },
  ],
  benefits: [
    {
      subscription_description: [
        {
          type: "paragraph",
          text: "Stream from your TV, tablet, computer or phone",
          spans: [],
        },
      ],
      subscription_icon: {
        alt: "benefit",
        url: "https://place-hold.it/10x10",
      },
    },
  ],
  propgressSteps: [
    { name: "Account", completed: true },
    { name: "Payment", active: true },
    { name: "Summary" },
  ],
  answersWithImage: [
    {
      text:
        "You can cancel your LES MILLS On Demand subscription at any time in the ‘Subscription Details’ section of My Account.",
    },
    {
      image: {
        url: "urlImg",
        alt: "altImg",
      },
    },
  ],
  time: "2020-01-02T23:26:43-05:00",
  lmod_gu_err: [
    {
      type: "paragraph",
      text: "Failed on getting user detail",
      spans: [],
    },
  ],
  subscription: {
    currentuser_id: "3fa4259c-b20c-4816-93d6-99561ee26169",
    product_handle: "lmod-de-daily",
    product: {
      currency: "NZD",
      product_handle: "lmod-de-daily",
      name: "LMOD Daily Plan",
      description: "Daily Plan for LMOD",
      product_price_point: {
        product_handle: "lmod-de-daily",
        price_point_id: 0,
        price_in_cents: 3,
        interval: 1,
        interval_unit: "day",
        trial_price_in_cents: 0,
        trial_interval: 7,
        trial_interval_unit: "day",
        initial_charge_in_cents: null,
        initial_charge_after_trial: true,
        currency: "NZD",
      },
    },
    monthlySubscription: {
      product_handle: "lmod-de-monthly",
      currency: "EUR",
      product_price_point: {
        price_point_id: 0,
        price_in_cents: 100,
        interval: 1,
        interval_unit: "month",
        trial_price_in_cents: 0,
        trial_interval: 7,
        trial_interval_unit: "month",
        initial_charge_in_cents: null,
        initial_charge_after_trial: true,
      },
    },
    state: "canceled",
    current_period_ends_at: "2020-01-03T07:38:36-05:00",
  },
  dailyProduct: {
    product_handle: "lmod-de-daily",
    name: "LMOD Daily Plan",
    description: "Daily Plan for LMOD",
    currency: "EUR",
    product_price_point: {
      product_handle: "lmod-de-daily",
      price_point_id: 0,
      price_in_cents: 3,
      interval: 1,
      interval_unit: "day",
      trial_price_in_cents: 0,
      trial_interval: 7,
      trial_interval_unit: "day",
      initial_charge_in_cents: null,
      initial_charge_after_trial: true,
    },
  },
  annualProduct: {
    product_handle: "lmod-annual-test",
    name: "LMOD Annual Plan - Test",
    description: "Daily Plan for LMOD",
    currency: "EUR",
    product_price_point: {
      product_handle: "LMOD Annual Plan - Test",
      price_point_id: 0,
      price_in_cents: 3000,
      interval: 12,
      interval_unit: "month",
      trial_price_in_cents: 0,
      trial_interval: null,
      trial_interval_unit: null,
      initial_charge_in_cents: null,
      initial_charge_after_trial: true,
    },
  },
  annualProductWithTrial: {
    product_handle: "lmod-annual-test",
    name: "LMOD Annual Plan - Test",
    description: "Daily Plan for LMOD",
    currency: "EUR",
    product_price_point: {
      product_handle: "LMOD Annual Plan - Test",
      price_point_id: 0,
      price_in_cents: 3000,
      interval: 12,
      interval_unit: "month",
      trial_price_in_cents: 0,
      trial_interval: 1,
      trial_interval_unit: null,
      initial_charge_in_cents: null,
      initial_charge_after_trial: true,
    },
  },
  monthlySubscriptionWithIntervals: {
    product_handle: "lmod-de-monthly",
    currency: "EUR",
    product_price_point: {
      price_point_id: 0,
      price_in_cents: 100,
      interval: 3,
      interval_unit: "month",
      trial_price_in_cents: 0,
      trial_interval: 7,
      trial_interval_unit: "month",
      initial_charge_in_cents: null,
      initial_charge_after_trial: true,
    },
  },
  newProduct: {
    product_handle: "lmod-annual-test",
    name: "LMOD Annual Plan - Test",
    description: "Daily Plan for LMOD",
    currency: "EUR",
    product_price_point: {
      product_handle: "LMOD Annual Plan - Test",
      price_point_id: 0,
      price_in_cents: 3000,
      interval: 2,
      interval_unit: "year",
      trial_price_in_cents: 0,
      trial_interval: null,
      trial_interval_unit: null,
      initial_charge_in_cents: null,
      initial_charge_after_trial: true,
    },
  },
  location: {
    state: {
      prevPath: "",
    },
  },
  months: {
    months: [
      {
        items: [
          {
            name: {
              text: "Jan",
            },
            id: "jan",
          },
          {
            name: {
              text: "Feb",
            },
            id: "feb",
          },
        ],
      },
    ],
  },
  planNotes: {
    text:
      "Free trial only available to new customers. LES MILLS On Demand content is in English.",
    raw: [
      {
        type: "paragraph",
        text:
          "Free trial only available to new customers. LES MILLS On Demand content is in English.",
        spans: [],
      },
    ],
  },
  subscriptionCanceled: {
    currentuser_id: "3fa4259c-b20c-4816-93d6-99561ee26169",
    product_handle: "lmod-de-daily",
    product: {
      product_handle: "lmod-de-daily",
    },
    state: "canceled",
    current_period_ends_at: "2020-01-03T07:38:36-05:00",
  },
  subscriptionProcessing: {
    currentuser_id: "3fa4259c-b20c-4816-93d6-99561ee26169",
    product_handle: "lmod-de-daily",
    product: {
      product_handle: "lmod-de-daily",
    },
    state: "processing",
    current_period_ends_at: "2020-01-03T07:38:36-05:00",
  },
  subscriptionOnDelayCancel: {
    currentuser_id: "3fa4259c-b20c-4816-93d6-99561ee26169",
    product_handle: "lmod-de-daily",
    product: {
      product_handle: "lmod-de-daily",
    },
    state: "on-delayed-cancellation",
    current_period_ends_at: "2020-01-03T07:38:36-05:00",
  },
  countries: [
    { value: "hk", name: "Hong Kong" },
    { value: "hu", name: "Hungary" },
    { value: "us", name: "United State" },
  ],
  tabs: [
    {
      image: {
        dimensions: {
          width: 1000,
          height: 2937,
        },
        alt: "desktop",
        copyright: null,
        url: "https://place-hold.it/1000x2937",
      },
      tab: {
        type: "paragraph",
        text: "Desktop",
        spans: [],
      },
      name: null,
    },
    {
      image: {
        dimensions: {
          width: 1000,
          height: 7184,
        },
        alt: "mobile",
        copyright: null,
        url: "https://place-hold.it/1000x7184",
      },
      tab: {
        type: "paragraph",
        text: "Mobile",
        spans: [],
      },
      name: null,
    },
  ],
  states: [
    {
      id: 3901,
      name: "Badakhshan",
      code: "BDS",
    },
    {
      id: 3871,
      name: "Badghis",
      code: "BDG",
    },
    {
      id: 3875,
      name: "Baghlan",
      code: "BGL",
    },
  ],
  prismicStates: [
    {
      id: 3901,
      state_name: {
        text: "Badakhshan",
      },
      state_code: {
        text: "BDS",
      },
      state_short_name: {
        text: "BDS",
      },
    },
    {
      id: 3871,
      state_name: {
        text: "Badghis",
      },
      state_short_name: {
        text: "BDG",
      },
    },
    {
      id: 3875,
      state_name: {
        text: "Baghlan",
      },
      state_short_name: {
        text: "BGL",
      },
    },
  ],
}
