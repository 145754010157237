// @flow
import React from "react"

type RadioProps = {|
  id?: string,
  name: string,
  value: string,
  label?: string,
  checked?: Boolean,
  handleOnChange?: () => void | Promise<void>,
  classNames?: Object,
  testId?: string,
  innerRef: () => void | Promise<void>,
|}

const Radio = ({
  id = "",
  name,
  value,
  label = "",
  checked,
  handleOnChange = () => {},
  classNames = {},
  testId = "",
  innerRef = () => {},
}: RadioProps) => (
  <label className={classNames.wrapper ? classNames.wrapper : ""}>
    {label}
    <input
      ref={innerRef}
      id={id}
      type="radio"
      name={name}
      value={value}
      onChange={handleOnChange}
      checked={checked}
      className={classNames.input ? classNames.input : ""}
      data-cy={testId}
    />
    <span className={classNames.checkmark ? classNames.checkmark : ""} />
  </label>
)

export default React.memo<RadioProps>(Radio)
