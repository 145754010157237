import * as React from "react"
import isEqual from "lodash/isEqual"

import Cell from "./Cell"

type Props = {|
  headers: Array<String>,
  value: Object,
  classNames: String,
|}

const Row = ({ classNames, headers, value }: Props) => (
  <div
    className={
      "flex flex-wrap border-b border-gray-550 md:py-40 py-30" + classNames
    }
  >
    {headers.map((column, index) => {
      return (
        <Cell
          classNames={column.classNames}
          key={column.id + "-" + index}
          cell={value[column.id]}
        />
      )
    })}
  </div>
)

export default React.memo(Row, (prevProps, nextProps) => {
  return isEqual(prevProps.value, nextProps.value)
})
