import Auth from "@aws-amplify/auth"
import * as Sentry from "@sentry/browser"
import { SENTRY_ACTIONS } from "../constants/sentry-actions"
import { getLocalStorage, USER_INFO_KEY } from "./localstorage"

// export const isValidEnv = branch => {
//   return ["dev", "test", "preprod", "master"].find(
//     item => branch.indexOf(item) > -1
//   )
// }

export const isProductionBuild = process.env.NODE_ENV === "production"

export const isBypassCaptureError = hint => {
  const exception = hint ? hint.originalException : {}
  return (
    exception &&
    (exception.errorType === "NotAuthorizedException" ||
      exception.action === SENTRY_ACTIONS.CURRENT_AUTHENTICATE_USER ||
      exception.message === "Maximum call stack size exceeded") // bypass reCaptcha exception
  )
}

export const captureException = async err => {
  const userInfo = await getLocalStorage(USER_INFO_KEY)

  const sentryCapture = (error, email) =>
    Sentry.captureException(
      {
        action: error.action,
        errorType: error && (error.errorType || error.code || ""),
        errorInfo:
          (error.errorInfo && error.errorInfo.message
            ? error.errorInfo.message
            : error.errorInfo) ||
          error.name ||
          "",
        message: error.message,
        email: email,
        data: error.data || error,
        requestVariables: error.requestVariables || {},
      },
      scope => {
        // Add more tags for filtering the error
        scope.clear()
        scope.setTag("action", error.action)
        scope.setTag("error.message", error.message)
        return scope
      }
    )

  if (isProductionBuild) {
    let userEmail =
      (userInfo && userInfo.email ? userInfo.email : err.email) || ""
    if (!userEmail) {
      // Get from currentAuthenticatedUser
      const user = await Auth.currentAuthenticatedUser()
      userEmail = user && user.attributes ? user.attributes.email : ""
    }
    if (err instanceof Error) {
      sentryCapture(err, userEmail)
    } else {
      let sentryError = new Error(err.message)
      Object.assign(sentryError, err)

      sentryCapture(sentryError, userEmail)
    }
  }
}

export const captureMessage = msg => {
  if (isProductionBuild) {
    Sentry.captureException(msg)
  }
}
