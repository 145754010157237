/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import "./src/styles/index.css"

type Props = {|
  element: React.Node,
|}

export const wrapRootElement = ({ element }: Props) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_SITE_KEY_V3}>
    <PreviewStoreProvider initialEnabled={true}>{element}</PreviewStoreProvider>
  </GoogleReCaptchaProvider>
)
