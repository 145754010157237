import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import { LANGUAGE_OPTIONS, DEFAULT_LANGUAGE } from "../../constants/languages"
import { ROUTES } from "../../constants/routes"

import {
  generateUrlByLanguage,
  checkVisibleLanguageDropdownCountry,
} from "../../utils/utilities"
import {
  setLocalStorage,
  CURRENT_LANG,
  getSessionStorage,
  CURRENT_COUNTRY,
  getLocalStorage,
  USER_INFO_KEY,
  setSessionStorage,
} from "../../utils/localstorage"
import { publicAPI } from "../../utils/publicAPI"
import { getHeaders } from "../../graphql/getHeaders"

import CustomSelect from "../CustomSelect"
type Props = {|
  currentLang: string,
  classNames?: {
    wrapper?: string,
    select?: string,
    color?: string,
  },
  type?: "bold" | "light" | "default",
  isLoggedIn?: Boolean,
  location?: {
    pathname: string,
  },
  currentCountryCodeProp: string,
|}

const LanguageDropdown = ({
  currentLang = DEFAULT_LANGUAGE,
  type = "default",
  classNames = {},
  isLoggedIn = false,
  location = {},
  currentCountryCodeProp = "",
}: Props) => {
  const [currentCountry, setCurrentCountry] = useState({
    isVisible: false,
    country: "",
  })

  const { pathname = "" } = location

  useEffect(() => {
    // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
    // When user is logged in, based on the user's billing country. otherwise, based on IP address
    let countryCode = isLoggedIn
      ? getLocalStorage(USER_INFO_KEY).addressCountry
      : getSessionStorage(CURRENT_COUNTRY) || getLocalStorage(CURRENT_COUNTRY)

    // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
    // if there is not current country in localstorage or session storage then getHeaders
    if (typeof countryCode === "string") {
      setCurrentCountry({
        isVisible: checkVisibleLanguageDropdownCountry(countryCode),
        country: countryCode.toLowerCase(),
      })
    } else {
      if (!pathname.includes(ROUTES(currentLang).MEMBERSHIP)) {
        publicAPI(
          getHeaders,

          // Call back after get headers api
          ({ data }) => {
            countryCode = data.data.getHeaders.countryCode.toLowerCase()
            setCurrentCountryData(countryCode)
          }
        )
      }
    }
  }, [])

  useEffect(() => {
    currentCountryCodeProp &&
      setCurrentCountryData(currentCountryCodeProp.toLowerCase())
  }, [currentCountryCodeProp])

  const setCurrentCountryData = currentCountryCode => {
    setSessionStorage(CURRENT_COUNTRY, currentCountryCode)
    setLocalStorage(CURRENT_COUNTRY, currentCountryCode)

    setCurrentCountry({
      isVisible: checkVisibleLanguageDropdownCountry(currentCountryCode),
      country: currentCountryCode,
    })
  }

  const handleOnSelect = value => {
    if (value !== currentLang) {
      // Navigate to correct language page
      setLocalStorage(CURRENT_LANG, value)
      navigate(generateUrlByLanguage(value, currentLang))
    }
  }

  const setWrapperStyles = () => {
    switch (type) {
      case "light":
        return " font-base-light text-3xs md:text-2lg w-30 md:w-40 z-50"
      case "bold":
        return " font-primary text-3xs md:text-xl md:w-30 w-icon-md z-50"
      default:
        return " text-xxs md:text-3xs font-base-heavy lg:w-30 md:w-40 w-icon-lg md:mr-0 mr-30 z-50"
    }
  }

  const upIconStyles =
    type === "default" ? " md:text-2xs text-xxs" : " md:text-base text-2xs"

  const downIconStyles =
    type === "default"
      ? " md:text-2xs text-xxs md:top-7 top-5"
      : " md:text-base text-2xs top-8 md:top-11"

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
  // Language picker to be hidden if not in Germany & Austria
  return currentCountry.isVisible ||
    (currentLang && currentLang !== DEFAULT_LANGUAGE) ? (
    // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
    // if user goes to the de-de URL directly in non de & at
    // still display dropdown so that they can go back to the EN pages
    <CustomSelect
      options={
        LANGUAGE_OPTIONS[currentCountry.country] || LANGUAGE_OPTIONS.default
      }
      handleOnSelect={handleOnSelect}
      currentValue={currentLang}
      selectedIcon={<i className="fa fa-check text-xxs" />}
      icon={
        <>
          <i
            className={`fa fa-caret-up absolute left-100/100${upIconStyles}`}
          />
          <i
            className={`fa fa-caret-down absolute left-100/100${downIconStyles}`}
          />
        </>
      }
      classNames={{
        wrapper: ` uppercase lg:mr-20 mr-30 bg-transparent${setWrapperStyles()}${
          classNames.color ? classNames.color : " text-gray-800"
        }`,
        selectedItem: `${
          type === "light" ? " leading-5relaxed underline uppercase" : ""
        }`,
        list: " uppercase sm:w-play-icon-lg w-avatar rounded-3",
        listItem:
          "hover:text-white text-black flex items-center justify-end hover:bg-blue-500 bg-white pt-5",
        innerListItem: "sm:ml-10 ml-5 mr-10",
      }}
    />
  ) : null
}

export default LanguageDropdown
