import * as React from "react"

type Props = {|
  handleDismiss: () => void,
  children: React.Node,
  classNames?: Object,
  testId?: string,
  type: "base" | "sm" | "circle", // circle: type with circle x button
  nonePadding?: Boolean,
  isCustomisedContentPosition?: Boolean,
  corssButtonTheme?: "white" | "dark",
  heightAuto?: Boolean,
|}

const Modal = ({
  handleDismiss,
  children,
  classNames = {
    wrapper: "",
    content: "",
  },
  testId = "",
  type = "base",
  nonePadding = false,
  isCustomisedContentPosition = false,
  corssButtonTheme = "dark",
  heightAuto = false,
}: Props) => (
  <div
    className={`top-0 left-0 w-full h-100vh fixed z-100 overflow-auto ${
      classNames.wrapper ? classNames.wrapper : "bg-modal-base"
    } `}
    data-cy={testId}
    date-testid={testId}
  >
    <div
      className="modal-overlay absolute w-full h-full bg-modal-base top-0 left-0 cursor-pointer"
      onClick={handleDismiss}
    />
    <div
      className={`modal-content relative bg-white overflow-auto ${
        !isCustomisedContentPosition ? "top-1/2 left-1/2" : ""
      } ${classNames.content} ${
        type === "base" || type === "circle"
          ? heightAuto
            ? "h-auto"
            : "h-full md:max-h-9/10"
          : "min-w-modal-sm max-h-modal-sm"
      } ${
        type === "circle" || nonePadding ? "py-0" : "md:px-6 md:py-6 px-4 py-10"
      }`}
    >
      {type === "circle" ? (
        <div
          className={`${
            corssButtonTheme === "white"
              ? "border-white text-white"
              : "border-black text-black"
          } border-2 border-solid absolute cursor-pointer top-22 right-20 md:text-2xl text-base md:w-close-modal w-sm-close-modal text-center rounded-full`}
          onClick={handleDismiss}
          data-cy={testId ? `${testId}-cancel` : ""}
          data-testid={testId ? `${testId}-cancel` : ""}
        >
          X
        </div>
      ) : (
        <div
          className={`modal-close w-6 h-6 absolute cursor-pointer ${
            corssButtonTheme === "white"
              ? "modal-close-white top-22 right-30"
              : "modal-close-black md:top-30 md:right-40 top-10 right-13"
          } `}
          onClick={handleDismiss}
          data-cy={testId ? `${testId}-cancel` : ""}
          data-testid={testId ? `${testId}-cancel` : ""}
        />
      )}
      {children}
    </div>
  </div>
)

export default React.memo(Modal)
