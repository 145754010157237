// The pair of [error message: error custom type on prismic]
// Note: Should check error for 2 case:
//       Enable 3DS: the error message is without error code.
//       Disable 3DS: the error message is usually included error code.

export const CHARGIFY_ERRORS = {
  "The customer cannot be authenticated.":
    "threedsecure_failed_no_challenge_authentication_error",
  "CVV must be 4 digits for American Express and 3 digits for other card types.":
    "cvv_invalid_error",
  "CVV must be 4 digits for American Express and 3 digits for other card types. (81707)":
    "cvv_invalid_error",
  "Credit card type is not accepted by this merchant account.":
    "credit_card_not_accepted_by_merchant_error",
  "CVV is required.": "cvv_is_required_error",
  "Expiration date is required.": "expiration_date_is_required_error",
  "Expiration date is invalid.": "expiration_date_is_invalid_error",
  "Expiration year is invalid.": "expiration_year_invalid_error",
  "Expiration year is invalid. (81713)": "expiration_year_invalid_error",
  "Expiration year is invalid. It must be between 1975 and 2201.":
    "expiration_year_between_time_error",
  "Expiration month is invalid.": "expiration_month_is_invalid_error",
  "Credit card number is required.": "credit_card_is_required_error",
  "Credit card number is invalid.": "credit_card_is_invalid_error",
  "CVV verification failed.": "cvv_verification_failed_error",
  "Invalid PayPal account information.": "invalid_paypal_information_error",
  "We're still processing your payment information, this will take some seconds.":
    "processing_verify_credit_card_text",
  "2001": "payment_response_error_2001",
  "2004": "payment_response_error_2004",
  "2075": "payment_response_error_2075",
  "2068": "payment_response_error_2068",
}

export const BRAINTREE_ERRORS = [
  "2000",
  "2002",
  "2003",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
  "2041",
  "2042",
  "2043",
  "2044",
  "2045",
  "2046",
  "2047",
  "2048",
  "2049",
  "2050",
  "2051",
  "2052",
  "2053",
  "2054",
  "2055",
  "2056",
  "2057",
  "2058",
  "2059",
  "2060",
  "2061",
  "2062",
  "2063",
  "2064",
  "2065",
  "2066",
  "2067",
  "2069",
  "2079",
  "2071",
  "2072",
  "2073",
  "2074",
  "2076",
  "2077",
  "2078",
  "2079",
  "2080",
  "2081",
  "2082",
  "2083",
  "2084",
  "2085",
  "2086",
  "2087",
  "2088",
  "2089",
  "2090",
  "2091",
  "2092",
  "2093",
  "2094",
  "2095",
  "2096",
  "2097",
  "2098",
  "2099",
  "3000",
]
