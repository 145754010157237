const SUBSCRIPTION_STATES = {
  ACTIVE: "active",
  TRIALING: "trialing",
  ON_DELAYED_CANCELLATION: "on-delayed-cancellation",
  CANCELED: "canceled",
  PROCESSING: "processing",
  EXPIRED: "expired",
  ON_HOLD: "on_hold",
  PAST_DUE: "past_due",
  SOFT_FAILUE: "soft_failure",
  TRIAL_ENDED: "trial_ended",
  ASSESSING: "assessing",
  FAILED_TO_CREATE: "failed_to_create",
  PAUSED: "paused",
  PENDING: "pending",
  SUSPENDED: "suspended",
  UNPAID: "unpaid",
  FAILED: "failed",
}

export const ON_HOLD_STATES = [
  SUBSCRIPTION_STATES.ON_HOLD,
  SUBSCRIPTION_STATES.PAST_DUE,
  SUBSCRIPTION_STATES.EXPIRED,
  SUBSCRIPTION_STATES.UNPAID,
  SUBSCRIPTION_STATES.FAILED,
]

export const ROLLOVER_STATES = {
  complete: "COM",
  new: "NEW",
}

export default SUBSCRIPTION_STATES
