// @flow
import React, { useState } from "react"
import Icon from "../Icon"
import Popover from "../Popover"

type Props = {|
  label?: string,
  title?: string,
  description: React.Node,
  classNames?: Object,
|}

const TrialPopover = ({
  label = "",
  title = "",
  description,
  classNames = {},
}: Props) => {
  const [isOpenPopover, togglePopover] = useState(false)

  return (
    <Popover
      togglePopover={togglePopover}
      className={"items-center w-max-content h-max-content flex relative"}
    >
      <p
        className={
          classNames.label
            ? `${classNames.label}`
            : "font-base-light text-gray-800 leading-tight md:text-base text-3xs"
        }
      >
        {label}
      </p>
      <Icon
        icon="info-active"
        classNames="h-icon-base w-icon-base -mt-4 md:ml-12 ml-10 cursor-pointer"
        handleOnClick={() => togglePopover(!isOpenPopover)}
      />
      {isOpenPopover && (
        <div
          className={`md:w-popover w-100vw shadow-md border border-solid border-gray-100 absolute z-50 bg-white px-20 py-20 md:ml-0 -ml-20 top-22 ${classNames.content}`}
        >
          <div className="flex justify-between">
            <h5 className="font-base-black text-base text-gray-400 md:text-2lg mb-10">
              {title}
            </h5>
            <Icon
              icon="close"
              classNames="h-icon-base w-icon-base cursor-pointer"
              handleOnClick={() => togglePopover(!isOpenPopover)}
            />
          </div>
          <div className="w-full border-solid border-gray-550 my-10 border-t"></div>
          <div className="font-base-light text-3xs md:text-base text-gray-800">
            {description}
          </div>
        </div>
      )}
    </Popover>
  )
}

export default TrialPopover
