// @flow
import React, { useState } from "react"
import { navigate } from "gatsby"
import { RichText, Elements } from "prismic-reactjs"

import { ROUTES } from "../../constants/routes"
import { images } from "../../constants/media"
import { DEFAULT_LANGUAGE } from "../../constants/languages"

import { isEmpty } from "../../utils/utilities"
import { isBrowser } from "../../utils/localstorage"
import { linkResolver } from "../../utils/linkResolver"
import { propsWithUniqueKey } from "../../utils/prismic"

import type { UserType } from "../../types/UserType"
import type { ImageType } from "../../types/ImageType"

import Icon from "../Icon"
import Button from "../Button"
import Navigations from "../Navigations"
import HeaderDropdown from "../HeaderDropdown"
import LanguageDropdown from "../LanguageDropdown"
import { logoutTracking } from "../../utils/dataTracking"
type Props = {|
  navs: Object,
  user?: UserType,
  logo: Object,
  lang?: string,
  isLoading?: Boolean,
  prismicData: {
    sign_out_button: Array<LabelType>,
    sign_in_button: Array<LabelType>,
  },
  altLogo: ImageType,
|}

// Define constant to reuse 2 places in header dropdown
const headerDropdownItemClass =
  "font-base-heavy lg:font-base-light text-3xs lg:text-2lg lg:pl-20 w-logo-lmod-lg hover:bg-gray-95 lg:py-20 no-underline block text-left select-none text-gray-200"

const Header = ({
  navs,
  lang = DEFAULT_LANGUAGE,
  user = {},
  isLoading = true,
  prismicData,
}: Props) => {
  const [showSideBar, toggleSideBar] = useState(false)
  const navLeft = navs.left || []
  const navRight = navs.right || []
  const navRightNoSignIn = navRight.filter(item => item.id !== "subscribe-nav")

  const signInBtnLabel = prismicData.sign_in_button.text
  const signOutBtnLabel = prismicData.sign_out_button.text

  const isLoggedIn = !isEmpty(user)

  const allNavsForMobile = isLoggedIn
    ? [...navLeft.slice(0, -1), ...navRight]
    : [...navLeft.slice(0, -1), ...navRightNoSignIn]

  const homeNav = navLeft.filter(item => item.id === "home-nav")
  const homePageLink = homeNav.length
    ? homeNav[0].link_url
      ? homeNav[0].link_url.text
      : process.env.GATSBY_GETTING_STARTED_URL
    : process.env.GATSBY_GETTING_STARTED_URL

  const handleSignOut = () => {
    logoutTracking(user?.vimeoUserId)
    navigate(ROUTES().SIGN_OUT)
  }

  const handleNavigateSignIn = () => {
    navigate(ROUTES(lang).SIGN_IN)
  }

  const renderButton = (className, handleOnClick, label) => (
    <Button className={className} handleOnClick={handleOnClick}>
      {label}
    </Button>
  )

  // We need to add class inside a instead of li
  const customizeHeaderDropdownItem = () => {
    const htmlSerializer = (type, element, content, children, key) => {
      let props = {}

      if (type === Elements.paragraph) {
        props = {
          className: `${headerDropdownItemClass} rounded-t-7 border-transparent lg:border-gray-350 border-b`,
          href: `https://my.${process.env.GATSBY_LMOD_MY_DOMAIN}/account`,
        }
        return React.createElement(
          "a",
          propsWithUniqueKey(props, key),
          element.text
        )
      }

      return null
    }
    return htmlSerializer
  }

  const renderMyAccountLink = () =>
    navLeft.length > 0 ? (
      <RichText
        render={navLeft[navLeft.length - 1].label.raw}
        linkResolver={linkResolver}
        htmlSerializer={customizeHeaderDropdownItem()}
        classNames="text-gray-200"
      />
    ) : null

  // const renderSubscribeLink = () => (
  //   <RichText
  //     render={navRight[navRight.length - 2].label.raw || []}
  //     linkResolver={linkResolver}
  //   />
  // )

  return (
    <header
      className="relative header-wrapper flex top-0 w-full justify-between items-stretch px-24"
      data-cy="header"
    >
      <div className="lg:hidden flex items-center">
        {!showSideBar && (
          <Icon
            icon="sidebar-white"
            classNames="w-6 h-6 bg-contain lg:hidden cursor-pointer"
            handleOnClick={() => toggleSideBar(!showSideBar)}
          />
        )}
        {showSideBar && (
          <Icon
            icon="slider-prev-white"
            type="base"
            classNames="w-5 h-5 bg-contain lg:hidden cursor-pointer"
            handleOnClick={() => toggleSideBar(!showSideBar)}
          />
        )}
      </div>
      <div className="flex pb-3 lg:pb-0 items-center pl-20 lg:w-auto justify-center">
        {isBrowser && (
          <div classnames="w-32">
            <a href={homePageLink}>
              {/* There is another white logo on header for some pages */}
              <img
                src={images.headerLogoLmi}
                className="h-3"
                alt="lesmills-logo"
                data-cy="lesmills-logo"
              />
            </a>
          </div>
        )}
      </div>
      <Navigations
        navs={showSideBar ? allNavsForMobile : navLeft.slice(0, -1)}
        classNames={{
          wrapper: `lg:flex lg:flex-row lg:items-center ${
            showSideBar
              ? "lg:p-0 side-nav-wrapper flex-col absolute bg-gray-910 w-100vw z-40 lg:mt-0 left-0"
              : "hidden"
          }`,
          item:
            "px-6 lg:px-0 lg:h-full lg:ml-20 pt-6 lg:py-0 lg:ml-28 lg:pm-10 text-gray-205 font-base-heavy text-sm lg:text-xs leading-5 uppercase header-nav select-none flex items-center relative top-2",
        }}
        showSideBar={showSideBar}
        userLoggedIn={isLoggedIn}
        lastChild={
          !isLoading && (
            <div className="lg:hidden">
              {renderButton(
                "uppercase pt-24 pb-24",
                isLoggedIn ? handleSignOut : handleNavigateSignIn,
                isLoggedIn ? signOutBtnLabel : signInBtnLabel
              )}
            </div>
          )
        }
      />

      {/* This group will be hided until checking session action was done */}
      {!isLoading && (
        <div className="flex items-center justify-end lg:mr-20 mr-24">
          <LanguageDropdown
            currentLang={lang}
            classNames={{
              wrapper: " sm:ml-10 xl:mr-20 sm:mr-8 mr-20 h-15 relative",
            }}
            isLoggedIn={isLoggedIn}
          />
          <div className="lg:flex items-center hidden">
            <Icon
              icon="adidas"
              type="xl"
              classNames="w-7 bg-contain cursor-pointer md:ml-12"
              handleOnClick={() =>
                (window.location.href = navRight[0]?.link_url?.text)
              }
            />
            <Navigations
              navs={
                Object.keys(user).length === 0 ? navRight : navRightNoSignIn
              }
              classNames={{
                wrapper: `items-center lg:flex hidden sm:justify-end${
                  user.givenName ? " sm:min-w-80" : " sm:min-w-150"
                }`,
                item: `text-gray-205 font-base-heavy text-xs leading-5 uppercase lg:h-full ${
                  !isLoggedIn ? "lg:pl-12" : ""
                }`,
              }}
              showSideBar={showSideBar}
              userLoggedIn={isLoggedIn}
              lastChild={
                Object.keys(user).length === 0
                  ? renderButton(
                      "uppercase",
                      handleNavigateSignIn,
                      signInBtnLabel
                    )
                  : ""
              }
            />
            {user.givenName && (
              <HeaderDropdown
                userName={user.givenName}
                dropdownList={[
                  renderMyAccountLink(),
                  renderButton(
                    `${headerDropdownItemClass} rounded-b-7`,
                    handleSignOut,
                    signOutBtnLabel
                  ),
                ]}
              />
            )}
          </div>
        </div>
      )}
    </header>
  )
}

export default React.memo<Props>(Header)
