export const AXII_CONFIGS = {
  enabledCountries: [
    "ax", // Åland Islands
    "al", // Albania
    "ad", // Andorra
    "ai", // Anguilla
    "ag", // Antigua and Barbuda
    "ar", // Argentina
    "am", // Armenia
    "au", // Australia
    "at", // Austria
    "az", // Azerbaijan
    "bs", // Bahamas
    "bh", // Bahrain
    "bd", // Bangladesh
    "bb", // Barbados
    "be", // Belgium
    "bm", // Bermuda
    "bo", // Bolivia
    "ba", // Bosnia and Herzegovina
    "br", // Brasil
    "vg", // British Virgin Islands
    "bg", // Bulgaria
    "ca", // Canada
    "ky", // Cayman Islands
    "cl", // Chile
    "hk", // China, Hong Kong Special Administrative Region
    "co", // Colombia
    "cr", // Costa Rica
    "hr", // Croatia
    "cy", // Cyprus
    "cz", // Czech Republic
    "dk", // Denmark
    "dm", // Dominica
    "do", // Dominican Republic
    "ec", // Ecuador
    "ee", // Estonia
    "fi", // Finland
    "fr", // France
    "de", // Germany
    "gi", // Gibraltar
    "gr", // Greece
    "gd", // Grenada
    "gt", // Guatemala
    "hn", // Honduras
    "hu", // Hungary
    "is", // Iceland
    "in", // India
    "ie", // Ireland
    "il", // Israel
    "it", // Italy
    "jp", // Japan
    "jo", // Jordan
    "kz", // Kazakhstan
    "kr", // Korea, (South) Republic of
    "kw", // Kuwait
    "kg", // Kyrgyzstan
    "lv", // Latvia
    "lt", // Lithuania
    "lu", // Luxembourg
    "mk", // Macedonia
    "my", // Malaysia
    "mt", // Malta
    "mu", // Mauritius
    "mx", // Mexico
    "md", // Moldova
    "me", // Montenegro
    "ms", // Montserrat
    "ma", // Morocco
    "nl", // Netherlands
    "nc", // New Caledonia
    "nz", // New Zealand
    "ni", // Nicaragua
    "mp", // Northern Mariana Islands
    "no", // Norway
    "om", // Oman
    "pk", // Pakistan
    "pa", // Panama
    "py", // Paraguay
    "pe", // Peru
    "ph", // Philippines
    "pl", // Poland
    "pt", // Portugal
    "pr", // Puerto Rico
    "qa", // Qatar
    "re", // Reunion (an overseas department of France)
    "ro", // Romania
    "kn", // Saint Kitts and Nevis, Federation of
    "lc", // Saint Lucia
    "mf", // Saint Martin (French part of island)
    "vc", // Saint Vincent and the Grenadines
    "sa", // Saudi Arabia
    "rs", // Serbia
    "sg", // Singapore
    "sk", // Slovakia
    "si", // Slovenia
    "za", // South Africa
    "es", // Spain (Espana)
    "se", // Sweden
    "ch", // Switzerland
    "pf", // Tahiti, French Polynesia (an overseas collective of France)
    "tw", // Taiwan Province of China
    "tj", // Tajikistan
    "th", // Thailand
    "tt", // Trinidad and Tobago
    "tn", // Tunisia
    "tm", // Turkmenistan
    "tc", // Turks and Caicos Islands
    "ae", // United Arab Emirates
    "gb", // United Kingdom of Great Britain and Northern Ireland
    "us", // United States of America
    "vi", // United States Virgin Islands
    "uy", // Uruguay
    "uz", // Uzbekistan
    "vn", // Vietnam
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1548
  multiMonthRolloverCountries: [
    "am", // Armenia
    "az", // Azerbaijan
    "bh", // Bahrain
    "bd", // Bangladesh
    "ca", // Canada
    "jo", // Jordan
    "kz", // Kazakhstan
    "kw", // Kuwait
    "kg", // Kyrgyzstan
    "om", // Oman
    "pk", // Pakistan
    "qa", // Qatar
    "sa", // Saudi Arabia
    "za", // South Africa
    "tj", // Tajikistan
    "tm", // Turkmenistan
    "ae", // United Arab Emirates
    "uz", // Uzbekistan
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1548
  twelveMonthRolloverCountry: [
    "ax", // Åland Islands
    "al", // Albania
    "ad", // Andorra
    "am", // Armenia
    "at", // Austria
    "az", // Azerbaijan
    "bh", // Bahrain
    "bd", // Bangladesh
    "be", // Belgium
    "ba", // Bosnia and Herzegovina
    "bg", // Bulgaria
    "ca", // Canada
    "hr", // Croatia
    "cy", // Cyprus
    "cz", // Czech Republic
    "ee", // Estonia
    "fi", // Finland
    "fr", // France
    "gr", // Greece
    "hu", // Hungary
    "is", // Iceland
    "ie", // Ireland, Republic Of (Eire)
    "il", // Israel
    "it", // Italy
    "jo", // Jordan
    "kz", // Kazakhstan
    "kw", // Kuwait
    "kg", // Kyrgyzstan
    "lv", // Latvia
    "lt", // Lithuania
    "lu", // Luxembourg
    "mk", // Macedonia
    "mt", // Malta
    "mu", // Mauritius
    "md", // Moldova
    "me", // Montenegro
    "ma", // Morocco
    "nl", // Netherlands
    "nc", // New Caledonia
    "no", // Norway
    "om", // Oman
    "pk", // Pakistan
    "pl", // Poland
    "pt", // Portugal
    "re", // Reunion (an overseas department of France)
    "ro", // Romania
    "mf", // Saint Martin (French part of island)
    "sa", // Saudi Arabia
    "rs", // Serbia
    "sk", // Slovakia
    "sl", // Slovenia
    "za", // South Africa
    "es", // Spain (Espana)
    "se", // Sweden
    "ch", // Switzerland
    "tj", // Tajikistan
    "tn", // Tunisia
    "tm", // Turkmenistan
    "ae", // United Arab Emirates
    "pf", // Tahiti, French Polynesia (an overseas collective of France)
    "uz", // Uzbekistan
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1438
  // - The countries is validated zipcode and state
  needValidateStateZipcodeCountries: ["us", "ca"],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/AB23-340
  // - The countries are required state
  stateRequiredCountries: ["us", "ca", "au", "de", "at", "mx"],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1427
  // - Disable Paypal for countries that doesn't accept Paypal
  disabledPayPalCountries: [
    "ae", // United Arab Emirates
    "br", // Brasil
    "in", // India
    "kr", // Korea, (South) Republic of
    "my", // Malaysia
    "za", // South Africa
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1133
  // - The ‘Membership + Equipment’ will only be available in US & UK
  equipmentBundlesCountries: [
    "us", // United States of America (the)
    "gb", // Country code default for all countries include United Kingdom
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-876
  enabled3DSCountries: [
    "at", // Austria
    "be", // Belgium
    "bg", // Bulgaria
    "hr", // Croatia
    "cy", // Cyprus
    "cz", // Czech Republic
    "dk", // Denmark
    "ee", // Estonia
    "fi", // Finland
    "fr", // France
    "de", // Germany
    "gr", // Greece
    "hu", // Hungary
    "ie", // Ireland
    "it", // Italy
    "lv", // Latvia
    "lt", // Lithuania
    "lu", // Luxembourg
    "mt", // Malta
    "nl", // Netherlands
    "no", // Norway
    "pl", // Poland
    "pt", // Portugal
    "ro", // Romania
    "sk", // Slovakia
    "si", // Slovenia
    "es", // Spain (Espana)
    "se", // Sweden
    "gb", // United Kingdom
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1670
  // Countries of EU
  euCountries: [
    "at", // Austria
    "be", // Belgium
    "bg", // Bulgaria
    "hr", // Croatia
    "cy", // Cyprus
    "cz", // Czech Republic
    "dk", // Denmark
    "ee", // Estonia
    "fi", // Finland
    "fr", // France
    "de", // Germany
    "gr", // Greece
    "hu", // Hungary
    "ie", // Ireland
    "it", // Italy
    "lv", // Latvia
    "lt", // Lithuania
    "lu", // Luxembourg
    "mt", // Malta
    "nl", // Netherlands
    "no", // Norway
    "pl", // Poland
    "pt", // Portugal
    "ro", // Romania
    "sk", // Slovakia
    "si", // Slovenia
    "es", // Spain (Espana)
    "se", // Sweden
    "gb", // United Kingdom
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
  // Language picker to be hidden if not in Germany & Austria
  visibleLanguageDropdownCountries: [
    "de", // Germany
    "at", // Austria
    "jp", // Japan
    "br", // Brazil
    "fr", // France
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1229
  zeroDecimalCurrencyCountries: [
    "jp", // Japan
  ],

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1221
  // Display (plus tax) for us and ca
  standardSubscriptionCountries: ["us", "ca"],

  // https://lesmillsinternational.atlassian.net/browse/AB2B-826
  // Display product list with “price per month” format
  pricePerMonthFormatCountries: ["us", "ca", "nz", "in", "gb"],

  // https://lesmillsinternational.atlassian.net/browse/AB2B-834
  optInNewsletterCountries: ["us"],
}
