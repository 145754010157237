export const getListProducts = `
  query getValidProducts {
    getValidProducts {
      countryCode
      products {
        country_code
        product_handle
        currency
        customer_type
        name
        product_price_point {
          product_handle
          price_point_id
          price_in_cents
          interval
          interval_unit
          trial_price_in_cents
          trial_interval
          trial_interval_unit
          initial_charge_in_cents
          initial_charge_after_trial
        }
      }
    }
  }
`
