// @flow
import React from "react"

type Props = {|
  label?: string,
  options?: Array<{
    value: string,
    name: string,
  }>,
  classNames?: Object,
  handleOnChange?: () => void | Promise<void>,
  testId?: string,
  disabled?: boolean,
|}

const Dropdown = ({
  label = "",
  classNames = {
    dropdown: "",
    label: "",
    wrapper: "",
  },
  handleOnChange = () => {},
  options = [],
  testId = "",
  disabled,
}: Props) => (
  <div className={classNames.wrapper ? `${classNames.wrapper}` : ""}>
    <div className="relative">
      <select
        className={`${classNames.dropdown}${
          disabled ? " disabled " : ""
        }dropdown`}
        onChange={handleOnChange}
        data-cy={testId}
        disabled={disabled}
      >
        {options &&
          options.length > 0 &&
          options.map(item => (
            <option value={item.value} key={item.value}>
              {item.name}
            </option>
          ))}
      </select>
      <label
        className={
          classNames.label
            ? `dropdown-label ${classNames.label}`
            : "dropdown-label"
        }
      >
        {label}
      </label>
    </div>
  </div>
)

export default Dropdown
