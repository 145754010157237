import { useEffect, useState } from "react"
import { formStartTracking } from "../utils/dataTracking"

const useFormStartPushTracking = ({
  formName,
  planName,
  planType,
  planTier,
  planBillingFreq,
}) => {
  const [formStarted, setFormStarted] = useState(false)

  const handleFormStarted = () => {
    setFormStarted(true)
  }

  useEffect(() => {
    if (formStarted) {
      formStartTracking({
        formName,
        planName,
        planTier,
        planBillingFreq,
      })
    }
  }, [formName, planName, planType, planTier, planBillingFreq, formStarted])

  return { handleFormStarted }
}

export default useFormStartPushTracking
