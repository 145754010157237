// @flow
import React from "react"
import Icon from "../Icon"

type Props = {|
  type: "error" | "success" | "info",
  message: string,
  testId?: string,
  classNames?: Object,
|}

const Notification = ({
  type,
  message,
  testId = "",
  classNames = {},
}: Props) => {
  return (
    <div
      className={`${
        classNames.wrapper ? `flex ${classNames.wrapper}` : "flex mt-2"
      }`}
    >
      <div className="flex w-6 mr-2">
        {type === "error" && <Icon icon="alert" type="base" />}
        {type === "success" && (
          <i className="fa fa-check text-green-600 text-base md:text-xl" />
        )}
        {type === "info" && <Icon icon="info-inactive" type="base" />}
      </div>
      <div className="flex text-xs md:text-base items-center text-left font-base-light leading-tight">
        <p
          className={`mt-3 text-${type}`}
          data-cy={`${testId}-${type}`}
          data-testid={`${testId}-${type}`}
        >
          {message}
        </p>
      </div>
    </div>
  )
}

export default Notification
