// @flow
import React, { memo, useEffect } from "react"
import isEqual from "lodash/isEqual"
import isEmpty from "lodash/isEmpty"
import { useFlags, useLDClient } from "gatsby-plugin-launchdarkly"

import { LOGIN_STATUS, pageViewTracking } from "@lesmills/gatsby-theme-common"

import Footer from "../Footer"
import Header from "../Header"
import type { UserType } from "../../types/UserType"
import type { ImageType } from "../../types/ImageType"
import { TIERS, TIER_DEFAULT, NAV_TABS, parseJwt } from "../../utils/utilities"
import { isBrowser } from "../../utils/localstorage"

type Props = {|
  data: Object,
  children: React.Node,
  headerless?: Boolean,
  isLoading?: Boolean,
  lang: string,
  user: UserType,
  altLogo: ImageType,
|}

const Layout = ({
  data,
  children,
  headerless = false,
  lang,
  user = {},
  isLoading = true,
  altLogo = {},
}: Props) => {
  const {
    body = [],
    body1 = [],
    logo = {},
    sign_out_button = {},
    sign_in_button = {},
  } = data || {}
  const headerNav = {
    left: body[0] ? body[0].items : [],
    right: body[1] ? body[1].items : [],
  }
  const headerPrismicData = {
    sign_out_button,
    sign_in_button,
  }

  const TIERS_AV = Object.entries(TIERS).map(i => i[1])

  const getTierName = tierId => {
    // safe guard against anything being in the tier for now
    const name = tierId?.split("#")[1]
    return TIERS_AV.includes(name) ? name : TIER_DEFAULT
  }

  const userInfoJwt = parseJwt(
    isBrowser ? window.localStorage.getItem("jwtToken") : ""
  )
  const tier = userInfoJwt
    ? getTierName(userInfoJwt["cognito:groups"][1] ?? "")
    : TIER_DEFAULT

  const ldClient = useLDClient()

  if (userInfoJwt) {
    // Calling `identify` will cause the flags to be re-evaluated for the new
    // user that's logged in. Changes in flag values will stream in and could.
    // If this is a problem, set the flags inside the promise returned
    // from `ldClient.identify`.
    // can access flags anywhere in the app by calling `useFlags()`

    ldClient?.identify({
      key: userInfoJwt?.sub,
      custom: { tierId: tier },
    })
  }

  useEffect(() => {
    const userData = {
      ...user?.lmodSubscription,
      vimeoUserId: user?.vimeoUserId,
    }

    const loginStatus = !isEmpty(user)
      ? LOGIN_STATUS.LOGGED_IN
      : LOGIN_STATUS.LOGGED_OUT

    pageViewTracking(userData, loginStatus)
  }, [user])

  const flags = useFlags()

  // Not everything on the nav bar has been configured on LD hence hard coded ones are needed here
  const additionalTabs =
    tier === TIERS.PREMIUM ? NAV_TABS.PREMIUM : NAV_TABS.BASE
  // eslint-disable-next-line no-unused-vars
  const allowedOnly = Object.entries(flags).filter(([_, v]) => v === "allow")
  const allowedOnlyArray = Object.keys(Object.fromEntries(allowedOnly))
  // We want to filter out not not allowed tabs before rendering
  const tierBasedNavData = headerNav?.left.filter(link =>
    [...allowedOnlyArray, ...additionalTabs].some(item =>
      item
        .toLocaleLowerCase()
        .includes(link?.label?.text.toLocaleLowerCase().replaceAll(" ", ""))
    )
  )
  if (flags?.rtTierNavigation) {
    headerNav.left = tierBasedNavData
  }

  return (
    <>
      <div className="flex flex-col justify-between min-h-100vh">
        <div className="mt-0">
          {!headerless && (
            <Header
              navs={headerNav}
              logo={logo}
              lang={lang}
              user={user}
              isLoading={isLoading}
              prismicData={headerPrismicData}
              altLogo={altLogo}
            />
          )}
          <div className="relative">{children}</div>
        </div>
        <Footer navs={body1[0] ? body1[0].items : []} lang={lang} user={user} />
      </div>
    </>
  )
}

export default memo(Layout, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.user, nextProps.user) &&
    prevProps.headerless === nextProps.headerless &&
    prevProps.isLoading === nextProps.isLoading
  )
})
