import React from "react"
import { navigate } from "gatsby"
import { API, graphqlOperation } from "aws-amplify"
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
  LANGUAGE_BY_COUNTRY_CODE,
} from "../constants/languages"
import DATETIME_FORMAT from "../constants/datetime-format"
import { ERRORS } from "../constants/errors"
import { GetUser } from "../graphql/getUser"
import { client } from "./client"
import {
  setLocalStorage,
  getLocalStorage,
  USER_INFO_KEY,
  CURRENT_LANG,
  isBrowser,
  CREATE_ACCOUNT_STATUS,
  getSessionStorage,
  CURRENT_COUNTRY,
} from "./localstorage"
import {
  isZeroDecimalCurrency,
  hasSubscriptionUser,
  isIAP,
} from "./subscriptions"
import { captureException } from "./sentry"
import { SENTRY_ACTIONS } from "../constants/sentry-actions"
import { AXII_CONFIGS } from "../../../../src/configs/axii-config"
import { handleErrorWithPrismic } from "./prismic"

export const checkCurrentSub = (withSubCallBack, withoutSubCallback, user) => {
  // In case, user have not subs or account was canceled sub, we also need to use withoutSubCallback
  // AB2B-695: ApplePay users are considered active sub, but lmosSubscription is null
  const callBack =
    isIAP(user) || hasSubscriptionUser(user.lmodSubscription)
      ? withSubCallBack // This will be navigated to My account
      : withoutSubCallback // This will be navigated to step 2 or create account process

  callBack(user)
}

export const getParamsFromURL = paramName => {
  // refer: https://stackoverflow.com/questions/1403888/get-escaped-url-parameter/8764051#8764051
  if (isBrowser) {
    var match = RegExp("[?&]" + paramName + "=([^&;]+?)(&|#|;|$)").exec(
      window.location.href
    )
    /*
     * The encodeURIComponent() function encodes a URI component.
     * It encodes the following characters: , / ? : @ & = + $ #
     */
    return match && decodeURIComponent(match[1].replace(/\+/g, "%20"))
  }
}

export const getIdFromURL = () => {
  let id = ""
  if (typeof window !== "undefined" && window.location.hash) {
    id = location.hash.slice(1)
  }
  return id
}

export const formatBenefits = benefits =>
  benefits.map(item => ({
    content: item.subscription_description,
    icon: item.subscription_icon,
  }))

export const formatDateTime = (time, format, { body2 = [] } = {}) => {
  let date = []
  let formatDate = ""
  let months = body2[0]

  if (time) {
    let momentDate = new Date(time)

    if (format === DATETIME_FORMAT.default) {
      months = months ? months.items : []
      const shortMonth = momentDate.toDateString().substr(4, 3)

      let shortMonthOnPrismic = months.find(
        item => item.id === shortMonth.toLowerCase()
      )

      shortMonthOnPrismic = shortMonthOnPrismic && shortMonthOnPrismic.name.text

      date = [
        momentDate.getDate(),
        shortMonthOnPrismic,
        momentDate.getFullYear(),
      ]
      formatDate = date.join(" ")
    } else if (format === DATETIME_FORMAT.withSlash) {
      date = [
        momentDate.getDate(),
        momentDate.getMonth() + 1,
        momentDate.getFullYear(),
      ]
      formatDate = date.join("/")
    } else if (format === DATETIME_FORMAT.internalStandard) {
      date = [
        momentDate.getFullYear(),
        momentDate.getMonth() + 1,
        momentDate.getDate(),
      ]
      formatDate = date.join("-")
    }
  }

  return formatDate
}

export const convertCentsToEuro = (
  cents = 0,
  currency = "",
  toFixedNumber = null
) => {
  if (isZeroDecimalCurrency(currency)) {
    return cents
  }
  if (toFixedNumber) {
    return (cents / 100).toFixed(toFixedNumber)
  }
  return cents / 100
}

export const formatIntervalUnit = (
  { interval, interval_unit } = {},
  prismicData = {},
  isCapitalize = false,
  concatString = "-",
  isShowSingularInterval = false
) => {
  if (!interval_unit || !interval) {
    return
  }

  const isAnnual =
    interval_unit === "month" && interval % 12 === 0 && interval !== 0
  const isPlural = (!isAnnual && interval > 1) || (isAnnual && interval > 12)

  interval_unit = isAnnual ? "year" : interval_unit
  interval_unit = isPlural
    ? prismicData[interval_unit + "_plural"].text
    : prismicData[interval_unit + "_singular"].text

  interval_unit = isCapitalize ? capitalize(interval_unit) : interval_unit
  return isPlural
    ? (isAnnual ? interval / 12 : interval) + concatString + interval_unit
    : isShowSingularInterval
    ? interval + concatString + interval_unit
    : interval_unit
}

export const getFullNameUser = (given_name, family_name) => {
  return [given_name, family_name].join(" ").trim()
}

// :checked doesn't work on uni test, we need to use [checked]
const getRadioCheckedElement = name =>
  document.querySelector(`input[name=${name}]:checked`) ||
  document.querySelector(`input[name=${name}][checked]`)

export const getRadioChecked = name => {
  const checkedRadioElement = getRadioCheckedElement(name)
  return checkedRadioElement ? checkedRadioElement.value : ""
}

export const getIdRadioChecked = name => {
  const checkedRadioElement = getRadioCheckedElement(name)
  return checkedRadioElement ? checkedRadioElement.id : ""
}

export const getLabelRadioChecked = name => {
  const checkedRadioElement = getRadioCheckedElement(name)
  return checkedRadioElement ? checkedRadioElement.parentNode.textContent : ""
}

export const generateSecureRandomNumber = () => {
  const array = new Uint32Array(1)
  let randomNumber
  if (typeof window !== "undefined" && typeof window.crypto !== "undefined") {
    randomNumber = window.crypto.getRandomValues(array)[0]
  }
  return randomNumber
}

// After updating payment, there are a timeStamp was added in localStorage
// We will base on this to check update payment detail was finished after 5s or not
export const checkTimeAfterUpdatedPayment = (localStorageData, second) => {
  return (
    !isEmpty(localStorageData) &&
    localStorageData.timeStamp &&
    Math.abs(new Date().getTime() - localStorageData.timeStamp) / 1000 > second
  )
}

export const getCurrentUser = async (
  setUser = () => {},
  setLoading = () => {},
  setErrors = () => {},
  errorMessage = "",
  prismicData = {},
  query = GetUser
) => {
  try {
    client()
    const res = await API.graphql(graphqlOperation(query))
    setLoading(false)

    // Handle error with prismic data
    if (res && res.errors && res.errors.length > 0) {
      handleErrorWithPrismic(res.errors, errorMessage, setErrors, prismicData)
    }

    const user = res && res.data && res.data.getUser

    // Check to keep timeStamp after update payment details
    const existingUserData = getLocalStorage(USER_INFO_KEY)
    if (
      isEmpty(existingUserData) ||
      (!isEmpty(existingUserData) && !existingUserData.timeStamp) ||
      checkTimeAfterUpdatedPayment(existingUserData, 5)
    ) {
      setLocalStorage(USER_INFO_KEY, {
        // Just update properties, no replace
        ...existingUserData,
        ...user,
      })
    }

    if (setUser) {
      setUser(user)
    }
    return user
  } catch (err) {
    const currentUser =
      err && err.data && err.data.getUser ? err.data.getUser : null
    let errorType = "",
      errorInfo = "",
      message = ""

    const errors = err && err.errors
    if (errors && Array.isArray(errors)) {
      // Concat all errors to support tracking on Sentry
      errors.forEach((item, index) => {
        const splitString = index === errors.length - 1 ? "" : " | "
        errorType = errorType.concat(item.errorType + splitString)
        errorInfo = errorInfo.concat(item.errorInfo + splitString)
        message = message.concat(item.message + splitString)
      })
    } else {
      errorInfo = JSON.stringify(err)
      errorType = err && err.errorType
      message = err && err.message
    }

    captureException({
      action: SENTRY_ACTIONS.GET_USER,
      errorType,
      errorInfo,
      message,
      email: currentUser ? currentUser.email : "",
    })

    setLoading(false)

    // Check and save user for only valid data
    if (currentUser) {
      setUser(currentUser)
      return
    }

    setErrors({
      type: "error",
      message: errorMessage,
    })
  }
}

export const splitUpdateLabel = (label = "") =>
  label.replace(" ", "&").split("&")

export const capitalize = string => {
  if (!string) {
    return
  }
  return string[0].toUpperCase() + string.slice(1)
}

export const isEmpty = obj => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

export const validateCountry = (value, countries) => {
  return countries.find(item => item.name.toLowerCase() === value.toLowerCase())
}

export const parseCountryCodeToText = (value, data, key = "") => {
  // Some accounts are still return country text "Germany" instead of code "DE" or states text "Baghlan" to state code "BGL"
  // So this will check to avoid crash error
  return value && data && data.length > 0
    ? data.find(
        i =>
          (i.value && i.value.toLowerCase() === value.toLowerCase()) ||
          (i.name && i.name.toLowerCase() === value.toLowerCase()) ||
          (i[key] && i[key].toLowerCase() === value.toLowerCase())
      ) || {}
    : {}
}

export const getCountryNameByCode = (value, countries) => {
  var result = countries.find(
    item => item.value.toLowerCase() === value.toLowerCase()
  )
  return result ? result.name : ""
}

export const validatePostalCode = value => {
  return !!value && typeof value === "string"
}

export const formatOverviewTabs = data => {
  const tabs = {}

  data.forEach(item => {
    if (item) {
      tabs[item.tab.text] = item.image
    }
  })

  return tabs
}

export const generateUrlByLanguage = (val, lang) => {
  val = val === DEFAULT_LANGUAGE ? "" : "/" + val
  lang = lang === DEFAULT_LANGUAGE ? "" : "/" + lang
  let navigateUrl = ""

  if (isBrowser) {
    const pathname = window.location.pathname
    const params = window.location.search

    navigateUrl = pathname.replace(lang, val) + params
  }

  return navigateUrl
}

export const getCookie = () => {
  return (
    isBrowser &&
    window.document.cookie.split("; ").reduce((prev, current) => {
      const [name, value] = current.split("=")
      prev[name] = value
      return prev
    }, {})
  )
}

export const checkToRedirectCurrentLangPage = async lang => {
  let defaultBrowserLanguage =
    isBrowser &&
    window.navigator &&
    window.navigator.language &&
    window.navigator.language.toLowerCase()
  defaultBrowserLanguage =
    LANGUAGE_BY_COUNTRY_CODE[defaultBrowserLanguage.split("-")[0]] ||
    DEFAULT_LANGUAGE

  const location = isBrowser && window.location
  const savedLang = await getLocalStorage(CURRENT_LANG)
  const currentLanguage =
    typeof savedLang === "string" ? savedLang : defaultBrowserLanguage
  const langCode = currentLanguage.split("-")[0]
  const pageLang = lang.split("-")[0]

  // To checking redirect not supported language to current language
  const redirectUrl = location.href.replace(location.origin, "")
  const langOnURL = location.pathname.split("/")[1]
  const isValidLangURL = langOnURL.length === 5 && langOnURL[2] === "-"
  const isPreviewMode = getCookie()["io.prismic.preview"]

  if (
    !isPreviewMode &&
    location &&
    (langCode !== pageLang ||
      (isValidLangURL && pageLang !== langOnURL.split("-")[0]))
  ) {
    if (langCode === DEFAULT_LANGUAGE.split("-")[0]) {
      setLocalStorage(CURRENT_LANG, DEFAULT_LANGUAGE)
      if (isValidLangURL) {
        // Redirect not supported lang to current lang
        navigate(redirectUrl.replace("/" + langOnURL, ""))
      } else {
        navigate(redirectUrl.replace("/" + lang, ""))
      }
    } else if (SUPPORTED_LANGUAGES.includes(langCode)) {
      setLocalStorage(CURRENT_LANG, currentLanguage)
      if (isValidLangURL) {
        // Redirect not supported lang to current lang
        navigate(
          redirectUrl.replace(langOnURL, LANGUAGE_BY_COUNTRY_CODE[langCode])
        )
      } else {
        // Redirect other lang to current lang
        navigate("/" + currentLanguage + redirectUrl)
      }
    }
  }
}

export const getProgramFieldsById = (fields = [], id = "") =>
  fields.find(item => item.primary.program_id === id) || {}

export const calculatePercentOffMonthlyPrice = (
  { price_in_cents, interval, interval_unit, final_price_in_cents } = {},
  monthlySubscription = {},
  isPromotionOffer = false
) => {
  price_in_cents = price_in_cents || final_price_in_cents
  let monthlyPrice =
    monthlySubscription.product_price_point &&
    monthlySubscription.product_price_point.price_in_cents

  let percent = 0
  if (interval === 1 && interval_unit === "month" && !isPromotionOffer) {
    return
  }

  switch (interval_unit) {
    case "month":
      monthlyPrice = monthlyPrice * interval
      percent = (monthlyPrice - price_in_cents) / monthlyPrice
      break

    // Current this case is skipped, because annual subscription also have interval_unit "month"
    case "year":
      monthlyPrice = monthlyPrice * interval * 12
      percent = (monthlyPrice - price_in_cents) / monthlyPrice
      break

    default:
      price_in_cents = price_in_cents * 30
      percent = (monthlyPrice - price_in_cents) / monthlyPrice
      break
  }
  percent = parseInt(percent * 100)

  return percent
}

export const checkCurrentUser = (
  user = {},
  withoutSubCallback = () => {},
  withSubCallback = () => {},
  redirectURL = "",
  lang = DEFAULT_LANGUAGE,
  handleErrorUpdateUser = () => {}
) => {
  // if user has sub (include cancelled sub), will navigate to my account
  // AB2B-695: ApplePay users are considered active sub, but lmosSubscription is null
  if (user.lmodSubscription || isIAP(user)) {
    withSubCallback(user, redirectURL, lang, handleErrorUpdateUser)
  } else {
    // Redirect to create account when no have subscription or canceled affiliate
    setLocalStorage(CREATE_ACCOUNT_STATUS, "come-back")
    withoutSubCallback(user, lang, handleErrorUpdateUser)
    return
  }
}

export const is3DSChargifyError = (message = "") => {
  return message.indexOf("3DS") > -1 || message.indexOf("3D Secure") > -1
}

export const replaceSpaceInString = (str = "", substitute = "") => {
  return str.replace(/\s/g, substitute)
}

export const getPolicyDataByCountry = (countryCode, data) =>
  data.find(item => item.primary.country_code === countryCode)

export const hasParamsInURL = () => {
  if (isBrowser) {
    return !!window.location.search
  }
}

export const scrollToTop = () => {
  isBrowser && window.scrollTo(0, 0)
}

export const scrollToRef = ref =>
  isBrowser && window.scrollTo(0, ref.current.offsetTop)

export const splitString = (label = "", character = "&") =>
  label.replace(",", character).split(character)

export const debounce = (func, wait) => {
  var timeout

  return function() {
    let context = this,
      args = arguments

    let executeFunction = function() {
      func.apply(context, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(executeFunction, wait)
  }
}

export const highlightKeyword = (keyword = "", result = "") => {
  const resultLength = result.length

  // Do nothing for empty string
  if (resultLength === 0) {
    return result
  }

  // Get keyword index from result
  const keywordIndex = result.toLowerCase().indexOf(keyword.toLowerCase())
  const keywordLength = keyword.length

  switch (true) {
    case keywordIndex === -1:
      return result

    case keywordIndex === 0:
    case keywordIndex > 0:
      return (
        <>
          {keywordIndex > 0 && result.substr(0, keywordIndex)}
          <span className="font-base-medium font-black">
            {result.substr(keywordIndex, keywordLength)}
          </span>
          {resultLength > keywordIndex + keywordLength &&
            result.substr(keywordIndex + keywordLength, resultLength)}
        </>
      )
    default:
      return result
  }
}

export const filterStaticLocation = (
  location = [],
  countryCode = "",
  countries = []
) => {
  return (
    !!location &&
    location.data.filter(item =>
      item.iso2
        .toLowerCase()
        .includes(
          (
            parseCountryCodeToText(countryCode, countries).value || ""
          ).toLowerCase()
        )
    )
  )
}

export const getSelectedCountry = (prismicData, currentCountry) => {
  return (
    prismicData.find(
      ({ primary = { country_code: {} } }) =>
        primary.country_code.text.toLowerCase() === currentCountry.toLowerCase()
    ) || {}
  )
}

export const pluralize = (count, noun, pluralNoun) =>
  `${count !== 1 ? pluralNoun : noun}`

export const checkValidRef = ref => ref && ref.current

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1516
// Language picker to be hidden if not in Germany & Austria
export const checkVisibleLanguageDropdownCountry = country =>
  !!country &&
  AXII_CONFIGS.visibleLanguageDropdownCountries.indexOf(country.toLowerCase()) >
    -1

export const checkMultiMonthRolloverCountry = (country, rolloverCountries) =>
  !!country &&
  rolloverCountries?.threeMonth?.indexOf(country.toLowerCase()) > -1

export const checkIsTwelveMonthRolloverCountry = (country, rolloverCountries) =>
  !!country &&
  rolloverCountries?.twelveMonth?.indexOf(country.toLowerCase()) > -1

export const isResellerUser = user => user && user.type === "RS" // RS - Reseller
export const isResellerCustomerUser = user => user && user.type === "RC" // RC - Reseller customer

// Assign default value for object fields incase they're not valid
export const formatObjectFields = (object = {}, defaultValue = "") => {
  Object.entries(object).forEach(entry => {
    const [key, value] = entry
    if (!value) {
      object[key] = defaultValue
    }
  })
  return object
}

export const handleVerifyRecaptchaV2 = async (
  reCaptchaRef = null,
  handleRecaptchaTokenV2 = () => {}
) => {
  if (reCaptchaRef) {
    const tokenV2 = await reCaptchaRef.current.executeAsync()
    reCaptchaRef.current.reset()
    handleRecaptchaTokenV2(tokenV2)
  }
  return
}

export const handleFailedAPIWithRecaptcha = async (
  errorsAPI = [],
  callbackVerifyRecaptchaV2 = () => {},
  handleErrors = () => {}
) => {
  if (
    errorsAPI[0] &&
    errorsAPI[0].message &&
    errorsAPI[0].message.toLowerCase().includes(ERRORS.FailedRecaptchaV3)
  ) {
    callbackVerifyRecaptchaV2()
  } else {
    handleErrors()
  }
}

export const isOptInNewsletterCountry = () => {
  const country = getSessionStorage(CURRENT_COUNTRY)
  return country
    ? AXII_CONFIGS.optInNewsletterCountries.includes(country.toLowerCase())
    : false
}

export const parseJwt = token => {
  if (!token) return null
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace("-", "+").replace("_", "/")
  return JSON.parse(window.atob(base64))
}

export const TIERS = {
  BASE: "base",
  PREMIUM: "premium",
}

export const TIER_DEFAULT = "premium"

export const NAV_TABS = {
  BASE: [
    "Community",
    "Shop",
    "Explore",
    "Learn",
    "Blog(FitPlanet)",
    "MyAccount",
  ],
  PREMIUM: [
    "Explore",
    "GettingStarted",
    "Blog(FitPlanet)",
    "Equipment",
    "Community",
    "Shop",
    "Learn",
    "MyAccount",
    "Plans",
    "MyList",
  ],
}
