export const getPlaceDetails = `
  query getPlaceDetails($placeId: String!, $language: String) {
    getPlaceDetails(placeId: $placeId, language: $language) {
      address
      postalCode
      city
      state
      country
      stateCode
      countryCode
    }
  }
`
