import { CHARGIFY_ERRORS, BRAINTREE_ERRORS } from "../constants/chargify-errors"

export const getChargifyErrorPrismic = errorMessage => {
  return (
    CHARGIFY_ERRORS[
      Object.keys(CHARGIFY_ERRORS).find(item => errorMessage.includes(item))
    ] || "saving_payment_method_error"
  )
}

export const shouldShowOriginalBraintreeError = errorMessage => {
  // Error string example: "Processor declined: Do Not Honor (2000)"
  // When 3DS is enabled, 3DS errors will be returned and it's array, so no need check for 3DS array error
  if (typeof errorMessage === "string") {
    return BRAINTREE_ERRORS.includes(errorMessage.match(/\d+/)[0])
  }
  return false
}
