import Amplify, { Auth } from "aws-amplify"
import { navigate } from "gatsby"

import {
  setLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  getLocalStorage,
  getSessionStorage,
  CURRENT_COUNTRY,
  MEMBERSHIP_INFO,
  isBrowser,
  deleteLocalStorage,
  USER_INFO_KEY,
} from "./localstorage"

import {
  isEmpty,
  generateSecureRandomNumber,
  calculatePercentOffMonthlyPrice,
  checkIsTwelveMonthRolloverCountry,
  checkMultiMonthRolloverCountry,
  checkCurrentSub,
} from "./utilities"
import { isMonthlyProduct } from "./product"

import {
  updateUserCart,
  updateMembershipInfo,
  getOfferExpiryDate,
} from "./carts"

import { ROUTES } from "../constants/routes"
import { cognitoConfig } from "../configs/cognito-config"
import { publicAPI } from "./publicAPI"

import SUBSCRIPTION_STATES, {
  ON_HOLD_STATES,
  ROLLOVER_STATES,
} from "../constants/subscription-states"

export const handleUserWithoutSub = async (
  isAffiliate,
  createAccountRoute,
  user,
  handleErrorUpdateUser
) => {
  const product = getLocalStorage(NEW_SUBSCRIPTION_KEY)
  const isDifferentCountry =
    (user.addressCountry && user.addressCountry.toLowerCase()) !==
    getSessionStorage(CURRENT_COUNTRY).toLowerCase()

  // reset cart information if select offer
  // update cart information if select another product
  // https://lesmillsinternational.atlassian.net/browse/LA-1069
  // update cart info when select new product on different country
  if (
    isAffiliate ||
    product.product_handle !== user.cartProductHandle ||
    // https://lesmillsinternational.atlassian.net/browse/LA-1069
    // add flag isDifferentCountry to delete billing address
    isDifferentCountry
  ) {
    const membershipInfo = getLocalStorage(MEMBERSHIP_INFO)
    updateUserCart(
      product,
      isDifferentCountry,
      () => {
        navigate(`${createAccountRoute}?step=payment`)
      },
      handleErrorUpdateUser
    )

    // https://lesmillsinternational.atlassian.net/browse/LA-982
    // should update membership info if selected a lmnz offer
    if (product.isNZOffers && !isEmpty(membershipInfo)) {
      updateMembershipInfo(membershipInfo)
    }
  } else {
    navigate(`${createAccountRoute}?step=payment`)
  }
}

// Check whether subscription was immediately canceled or not (status will be 'canceled')
export const isImmediatelyCanceled = subscription => {
  return subscription.state === SUBSCRIPTION_STATES.CANCELED
}

export const hasSubscriptionUser = subscription => {
  return subscription && !isImmediatelyCanceled(subscription)
}

export const handleSaveProduct = (
  user,
  product,
  setLoading,
  lang,
  isAffiliate,
  isPartnerPromotion,
  handleErrorUpdateUser
) => {
  const createAccountRoute = getCreateAccountRoute(isAffiliate, lang)
  setLoading(true)
  setLocalStorage(NEW_SUBSCRIPTION_KEY, {
    ...product,
    ...(isAffiliate && {
      offerExpiredDate: getOfferExpiryDate(),
    }),
  })
  if (user && !isEmpty(user)) {
    // AB2B-672: Force log out and navigate to create account
    if (isPartnerPromotion) {
      Auth.signOut().then(() => {
        deleteLocalStorage(USER_INFO_KEY)
        navigate(ROUTES(lang).CREATE_ACCOUNT)
      })

      return
    }

    Amplify.configure(cognitoConfig)
    checkCurrentSub(
      // withSubCallBack
      () => {
        navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
      },
      // withoutSubCallback
      () => {
        handleUserWithoutSub(
          isAffiliate,
          createAccountRoute,
          user,
          handleErrorUpdateUser
        )
      },
      user
    )

    setLoading(false)
  } else {
    setLoading(false)

    // User redirecTo to trigger onBeforeUload when back from step 1
    if (isBrowser && window.location) {
      window.location.href = createAccountRoute
    }
  }
  return
}

export const getCreateAccountRoute = (isAffiliate, lang) =>
  isAffiliate
    ? ROUTES(lang).CREATE_ACCOUNT_AFFILIATE
    : ROUTES(lang).CREATE_ACCOUNT

export const getProduct = (
  setLoading,
  query,
  callback,
  handleErrorWithPrismic,
  variables = {}
) => {
  const runProgressBar = () => {
    setLoading(99)

    // delay 0,2s to show complete progress before hiding
    setTimeout(() => setLoading(100), 200)
  }

  const handleError = err => {
    runProgressBar()

    // Handle error with prismic data
    handleErrorWithPrismic(err)
  }

  const setProductList = ({ data }) => {
    callback(data)
    runProgressBar()
  }

  // random from 10 to 70: Math.random() * (max - min + 1) + min
  // use generateSecureRandomNumber()/100000000000 instead of Math.random() to fix Security Hotspots sonar
  setLoading((generateSecureRandomNumber() / 100000000000) * 61 + 10)

  publicAPI(query, setProductList, handleError, variables)
}
// LA-1432: use tune_offer_id instead of offer_id
export const getSubscriptionPrice = (
  product_price_point = {},
  tune_offer_id = null
) =>
  tune_offer_id
    ? product_price_point.final_price_in_cents
    : product_price_point.price_in_cents

export const getSubscriptionStatus = (currentSubscription = {}) => {
  const { state = "" } = currentSubscription
  const currentDate = new Date()
  const isDelayedCancelled =
    state === SUBSCRIPTION_STATES.ON_DELAYED_CANCELLATION &&
    currentSubscription.delayed_cancel_at

  if (
    isDelayedCancelled &&
    new Date(currentSubscription.delayed_cancel_at) < currentDate
  ) {
    return SUBSCRIPTION_STATES.ACTIVE
  } else if (isDelayedCancelled) {
    return SUBSCRIPTION_STATES.SUSPENDED
  } else if (ON_HOLD_STATES.indexOf(state) > -1) {
    return SUBSCRIPTION_STATES.ON_HOLD
  }
  return state
}

export const isLargerThanOneMonthSubscription = (subscription = {}) => {
  const { product_price_point = {} } = subscription || {}
  const { interval = 0, interval_unit = "" } = product_price_point || {}
  return (interval > 1 && interval_unit === "month") || interval_unit === "year"
}

export const allowRolloverPreferences = ({
  subscription = {},
  userCountry = "",
  rolloverCountries,
}) => {
  const currentCountry = userCountry
    ? userCountry
    : getSessionStorage(CURRENT_COUNTRY)
  // And subscription should be > 1 month

  const { product_price_point = {} } = subscription || {}
  const { interval = 0, interval_unit = "" } = product_price_point || {}

  // https://lesmillsinternational.atlassian.net/browse/LA-1548
  // subscription should be > 1 month with country in MultiMonthRolloverCountries
  const isMultiMonthRollover =
    isLargerThanOneMonthSubscription(subscription) &&
    checkMultiMonthRolloverCountry(currentCountry, rolloverCountries)

  // https://lesmillsinternational.atlassian.net/browse/LA-1548
  // subscription should be = 12 month with country in TwelveMonthRolloverCountries
  const isTwelveMonthRollover =
    interval === 12 &&
    interval_unit === "month" &&
    checkIsTwelveMonthRolloverCountry(currentCountry, rolloverCountries)

  const isAffiliate = isAffiliateUser(subscription) || !!subscription.offerId // LA-2080: Hide rollover if offer from tune

  return (
    (isMultiMonthRollover || isTwelveMonthRollover) &&
    // hide in create affiliate subscription process
    !isAffiliate
  )
}

/**
 * Mark user changed to another subscription
 * @param {Object} nextSubscription
 */
export const wasChangedSubscription = (
  nextSubscription = {},
  subscription = {}
) => {
  return !isEmpty(nextSubscription) && !!subscription.next_product_handle
}

/**
 * We should hide rollover preferences section when:
 * - There are any changes for subscription
 * - Suspended/Failed/Cancelled subscription
 * @param {Object} subscription
 */
export const hideRolloverPreferences = (
  nextSubscription = {},
  subscription = {}
) => {
  return (
    wasChangedSubscription(nextSubscription, subscription) ||
    [SUBSCRIPTION_STATES.FAILED, SUBSCRIPTION_STATES.CANCELED].indexOf(
      subscription.state
    ) > -1 ||
    subscription.delayed_cancel_at
  )
}

export const isNotCompletedSubscription = subscription =>
  subscription.rollover_status !== ROLLOVER_STATES.complete &&
  (subscription.state === SUBSCRIPTION_STATES.ACTIVE ||
    subscription.state === SUBSCRIPTION_STATES.TRIALING)

// This will be used to check user access offer link and has canceled subscription
export const hasCanceledSubOfferAccount = subscription => {
  return (
    !!getLocalStorage(NEW_SUBSCRIPTION_KEY).offerId &&
    subscription &&
    subscription.state === SUBSCRIPTION_STATES.CANCELED
  )
}

export const isActiveSubscription = state => {
  return (
    state === SUBSCRIPTION_STATES.ACTIVE ||
    state === SUBSCRIPTION_STATES.TRIALING ||
    state === SUBSCRIPTION_STATES.ON_DELAYED_CANCELLATION ||
    state === SUBSCRIPTION_STATES.PROCESSING ||
    state === SUBSCRIPTION_STATES.PAST_DUE
  )
}

export const getSubscriptionType = (
  { interval, interval_unit } = {},
  {
    annual_interval = {},
    monthly_interval = {},
    daily_interval = {},
    annually_interval = {},
  } = {},
  isOnSummary = false
) => {
  if (interval_unit === "month") {
    if (interval === 12) {
      return isOnSummary ? annually_interval.text : annual_interval.text
    }

    if (interval > 1) {
      return interval + "-" + monthly_interval.text
    }

    return monthly_interval.text
  }

  return daily_interval.text
}

export const isActiveRollover = (subscription, userCountry) =>
  subscription.default_to_monthly &&
  subscription.state === SUBSCRIPTION_STATES.ACTIVE &&
  allowRolloverPreferences(
    {
      ...subscription.product,
      tune_offer_id: subscription.tune_offer_id,
    },
    userCountry
  )

export const formatPlusTaxText = ({
  plus_tax_label = {},
  if_applicable = {},
} = {}) => plus_tax_label.text + ", " + if_applicable.text

export const setBestValueForSubscriptions = (subscriptions = []) => {
  if (subscriptions.length < 2) {
    return subscriptions
  }

  const monthlySubscription = subscriptions.find(item => isMonthlyProduct(item))

  let bestValueIndex = subscriptions.findIndex(item => isMonthlyProduct(item))

  let maxPercentOffMonthlyPrice = 0

  subscriptions.map((item, index) => {
    const itemPercentOffMonthlyPrice =
      monthlySubscription &&
      calculatePercentOffMonthlyPrice(
        item.product_price_point,
        monthlySubscription
      )
    if (itemPercentOffMonthlyPrice > maxPercentOffMonthlyPrice) {
      maxPercentOffMonthlyPrice = itemPercentOffMonthlyPrice
      bestValueIndex = index
    }
  })

  subscriptions[bestValueIndex] = {
    ...subscriptions[bestValueIndex],
    isBestValue: true,
  }

  return subscriptions
}

// LA-1810: Use offer_tune_id only to indicate affiliate user
export const isAffiliateUser = ({ tune_offer_id } = {}) => !!tune_offer_id

// LA-1229: Currency of Japanese products
export const isZeroDecimalCurrency = currency => currency === "JPY"

// LME-318: Update web customer/account page to show alternative subscription sources (Roku and Fire IAP)
// this object enable us to show alternative messages depending on the signup channel
// the string value of subscription and message must correspond to the keys in the MyAccount page in prismic
// whenever we add more signupchannels we need to update this function and the prismic custom type/page
// they will be later used in the packages/gatsby-theme-account-management/src/components/MyAccount/SubscriptionDetailSection.js component

export const signupChannelsMessages = {
  appleiap: {
    subscription: "applepay_subscription",
    message: "applepay_message",
  },
  vimeorokuiap: {
    subscription: "vimeo_roku_subscription",
    message: "vimeo_roku_message",
  },
  vimeoamazoniap: {
    subscription: "vimeo_amazon_fire_subscription",
    message: "vimeo_amazon_fire_message",
  },
  vimeoiap: {
    subscription: "vimeo_subscription",
    message: "vimeo_message",
  },
  customamazoniap: {
    subscription: "custom_amazon_subscription",
    message: "custom_amazon_message",
  },
  customgoogleiap: {
    subscription: "custom_google_subscription",
    message: "custom_google_message",
  },
}
export const isIAP = (user = {}) =>
  user.signupChannel
    ? Object.keys(signupChannelsMessages).includes(
        user.signupChannel.toLowerCase()
      )
    : false
