import { useStaticQuery } from "gatsby"

export const mockStaticMetaData = () => {
  useStaticQuery.mockImplementationOnce(() => ({
    site: {
      siteMetadata: {
        title: `Website`,
        description: `Website`,
        author: `@lesmills`,
        keywords: `Les Mills`,
      },
    },
  }))
}

export const mockWindowDataLayer = () => {
  Object.defineProperty(window, "dataLayer", {
    value: {
      push: jest.fn(),
    },
    writable: true,
  })
}
