const langsByCountry = require("../configs/langs-by-country")

const DEFAULT_COUNTRY = process.env.GATSBY_BUILD_COUNTRY || "default"
const DEFAULT_LANGUAGE = langsByCountry[DEFAULT_COUNTRY][0]
const SUPPORTED_LANGUAGES = ["de", "fr", "ja", "pt"]
const LANGUAGE_OPTIONS = {
  fr: [
    { label: "en", value: "en-nz" },
    { label: "fr", value: "fr-fr" },
  ],
  // Brazil
  // https://lesmillsinternational.atlassian.net/browse/AB2B-378
  br: [
    { label: "en", value: "en-nz" },
    { label: "pt", value: "pt-br" },
  ],
  // Japan
  jp: [{ label: "en", value: "en-nz" }], // LA-1863: FIXME: Will add more after Prismic content is ready
  // Germany
  de: [
    { label: "en", value: "en-nz" },
    { label: "de", value: "de-de" },
  ],
  // Austria
  at: [
    { label: "en", value: "en-nz" },
    { label: "de", value: "de-de" },
  ],
  default: [
    { label: "en", value: "en-nz" },
    { label: "de", value: "de-de" },
  ],
}

const LANGUAGE_BY_COUNTRY_CODE = {
  en: "en-nz",
  de: "de-de",
  fr: "fr-fr",
  ja: "ja-jp",
  pt: "pt-br",
}

module.exports = {
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
  LANGUAGE_OPTIONS,
  LANGUAGE_BY_COUNTRY_CODE,
}
