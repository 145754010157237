// @flow
import * as React from "react"
import Notification from "../Notification"

type TextboxProps = {|
  handleOnChange?: () => void | Promise<void>,
  handleOnKeyPress?: () => void | Promise<void>,
  handleOnFocus?: () => void | Promise<void>,
  handleOnBlur?: () => void | Promise<void>,
  handleOnPaste?: () => void | Promise<void>,
  value?: string,
  defaultValue?: string,
  inputRef?: {
    current: any,
  },
  label?: string,
  type?: string,
  classNames?: Object,
  id?: string,
  error?: string,
  success?: string,
  icon?: React.Node,
  testId?: string,
  disabled?: boolean,
  placeholder?: string,
|}

const Textbox = ({
  defaultValue = "",
  inputRef = {
    current: {},
  },
  value,
  label,
  type = "text",
  classNames = {
    textbox: "",
    label: "",
    wrapper: "",
    innerWrapper: "",
  },
  handleOnChange,
  handleOnPaste,
  handleOnKeyPress = () => {},
  handleOnFocus,
  handleOnBlur,
  error,
  success,
  icon,
  testId = "",
  id = "",
  disabled,
  placeholder,
}: TextboxProps) => (
  <div
    className={`textbox-wrapper md:mb-30 mb-26${
      classNames.wrapper ? " " + classNames.wrapper : ""
    }`}
  >
    <div
      className={`relative ${error ? "error" : ""}${classNames.innerWrapper ||
        ""}`}
    >
      <input
        className={`${classNames.textbox} textbox${
          disabled ? " disabled" : ""
        }`}
        type={type}
        value={value}
        placeholder={placeholder || label}
        onChange={handleOnChange}
        onKeyPress={e => handleOnKeyPress(e)}
        defaultValue={defaultValue}
        ref={inputRef}
        data-cy={testId}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        id={id}
        disabled={disabled}
        key={defaultValue}
        onPaste={handleOnPaste}
        autoComplete="off"
      />
      <label className={`textbox-label ${classNames.label}`} htmlFor={id}>
        {label}
      </label>
      {icon}
    </div>
    {error && <Notification type="error" message={error} testId={testId} />}
    {success && (
      <Notification type="success" message={success} testId={testId} />
    )}
  </div>
)

export default React.memo<TextboxProps>(Textbox)
