export const getOfferByHandle = `
  query getOfferByHandle($offer_handle: String!){
    getOfferByHandleV2(chargify_offer_handle: $offer_handle) {
      offer_id
      offer_handle
      product_handle
      name
      description
      product_name
      product_price_point {
        final_price_in_cents
        price_in_cents
        interval
        interval_unit
        trial_price_in_cents
        trial_interval
        trial_interval_unit
        currency
      }
    }
  }
`
