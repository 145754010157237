import * as React from "react"
import Icon from "../Icon"

type Props = {|
  item: {
    title: string,
    children: React.Node,
  },
  classNames?: {
    title?: string,
    icon?: string,
  },
  testId?: string,
  isOpenedAccordion?: boolean,
  isMultiple?: boolean,
  handleSelectItem?: () => void,
  innerRef?: () => void,
  onToggleFromOutside?: () => void,
  icon: string,
  outSideHeight: number,
  opened: boolean,
|}

const AccordionItem = ({
  item,
  classNames = { title: "", icon: "", accordion: "" },
  testId = "",
  icon,
  innerRef,
  onToggleFromOutside = () => {},
  outSideHeight,
  opened = false,
}: Props) => {
  const [isOpened, setOpened] = React.useState(opened)
  const [maxHeight, setMaxHeight] = React.useState(null)
  const contentRef = React.useRef(null)

  React.useEffect(() => {
    setMaxHeight(outSideHeight)
  }, [outSideHeight])

  // will re-render component right after receive new height from outside, no need to wait 400ms
  !outSideHeight &&
    setTimeout(() => {
      setMaxHeight(
        contentRef && contentRef.current && contentRef.current.scrollHeight
      )
    }, 400)

  return (
    <div
      ref={innerRef}
      className={`w-full${classNames.accordion || ""} accordion-item${
        isOpened ? " accordion-item-active" : ""
      }`}
    >
      <div
        className="flex justify-between items-center"
        data-cy={testId + "-title"}
        data-testid={testId + "-title"}
        onClick={() => {
          setOpened(!isOpened)
          onToggleFromOutside(maxHeight)
        }}
      >
        <div className={`cursor-pointer ${classNames.title}`}>{item.title}</div>
        <Icon
          icon={icon}
          classNames={`h-5 w-icon-xs cursor-pointer${classNames.icon}`}
        />
      </div>
      <div
        ref={contentRef}
        className="accordion-panel max-h-0 overflow-hidden"
        {...(isOpened && {
          style: { maxHeight: maxHeight ? maxHeight + "px" : null },
        })}
      >
        {item.children}

        {/* Only render collapseBtn if receive that prop */}
        {item.collapseBtn && (
          <item.collapseBtn handleCollapse={() => setOpened(false)} />
        )}
      </div>
    </div>
  )
}

export default AccordionItem
