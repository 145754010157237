// @flow
import React from "react"

type Props = {|
  currentValue: number,
  classNames?: Object,
|}

const ProgressBar = ({ currentValue, classNames = {} }: Props) => {
  return (
    <div
      className={`progress-bar-wrapper${
        classNames.wrapper ? classNames.wrapper : ""
      }`}
    >
      <div
        className={`progress-bar${
          classNames.progressBar ? classNames.progressBar : ""
        }`}
        aria-valuenow={currentValue}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${currentValue}%` }}
      />
    </div>
  )
}

export default ProgressBar
