export const getOfferById = `
  query getAffiliateOffers($offer_id: String!, $affiliate_id: String){
    getAffiliateOffers(tune_offer_id: $offer_id, tune_affiliate_id: $affiliate_id) {
      tune_offer_id
      offer_handle
      product_handle
      name
      description
      product_name
      recurring
      sort_order
      product_price_point {
        final_price_in_cents
        price_in_cents
        interval
        interval_unit
        trial_price_in_cents
        trial_interval
        trial_interval_unit
        currency
      }
      affiliate {
        logo_url
        name
      }
    }
  }
`
