import axios from "axios"

export const publicAPI = async (
  query,
  callback,
  handleError = () => {},
  variables = {},
  method = "post"
) => {
  try {
    const res = await axios({
      url: process.env.GATSBY_GRAPHQL_PUBLIC_ENDPOINT,
      method,
      data: {
        query,
        variables,
      },
    })
    callback(res)
  } catch (err) {
    // There are 2 cases for this catching:
    // - Error during executing any internal logic (there is no errors array there)
    // - Error throws from api (includes errors)
    handleError(err.errors && err.errors.length > 0 ? err.errors[0] : err)
  }
}
