const { DEFAULT_LANGUAGE } = require("../constants/languages")
const { ROUTES } = require("../constants/routes")

const linkResolver = doc => {
  const { type, lang, uid } = doc
  const langUrl = lang !== DEFAULT_LANGUAGE ? `/${lang}` : ""

  let route = ""

  switch (type) {
    case "sign_in_page":
      route = ROUTES(lang).SIGN_IN

      break

    case "forgot_password_page":
      route = ROUTES(lang).FORGOT_PASSWORD

      break

    case "reset_password_page":
      route = ROUTES(lang).RESET_PASSWORD

      break

    case "my_account_page":
      route = ROUTES(lang).CUSTOMER_ACCOUNT

      break

    case "update_personal_information_page":
      route = ROUTES(lang).EDIT_PERSONAL_INFORMATION

      break

    case "update_payment_details_page":
      route = ROUTES(lang).UPDATE_PAYMENT_DETAILS

      break

    case "change_subscription_page":
      route = ROUTES(lang).CHANGE_SUBSCRIPTION

      break

    case "membership_page":
      route = ROUTES(lang).MEMBERSHIP

      break

    case "create_account_page":
      route = ROUTES(lang).CREATE_ACCOUNT

      break

    case "create_reseller_account_page":
      route = `${langUrl}/${uid}`

      break

    case "email_newsletter":
      route = ROUTES(lang).EMAIL_NEWSLETTER

      break

    case "getting_started_page":
      route = ROUTES(lang).GETTING_STARTED

      break

    case "faq_page":
      route = ROUTES(lang).FAQ

      break

    case "not_found_page":
      route = ROUTES(lang).NOT_FOUND

      break

    case "check_email_page":
      route = ROUTES(lang).CHECK_EMAIL

      break

    case "cookie_policy_page":
      route = ROUTES(lang).COOKIE_POLICY

      break

    case "terms_of_use_page":
      route = ROUTES(lang).TERMS_OF_USE

      break

    case "privacy_policy_page":
      route = ROUTES(lang).PRIVACY_POLICY

      break

    case "member_discount_page":
      route = ROUTES(lang).MEMBER_DISCOUNT

      break

    case "marketing_sales_landing_pages":
      route = `${langUrl}/${uid}`

      break

    case "maintenance_page":
      route = ROUTES(lang).MAINTENANCE

      break

    case "personalized_offer_pages":
      route = `${ROUTES(lang).OFFERS}/${uid}`

      break

    case "challenges_page":
      route = `${ROUTES(lang).CHALLENGES}`

      break

    case "unsubscribe_challenges_pages":
      route = `${ROUTES(lang).UNSUBSCRIBE_CHALLENGES}`

      break

    case "payment_history_page":
      route = `${ROUTES(lang).PAYMENT_HISTORY}`

      break

    case "promotion_pages":
      route = `${ROUTES(lang).PROMOTION}`

      break

    case "offers_page":
      route = `${ROUTES(lang).OFFERS}`

      break

    case "reseller_redeem_page":
      route = `${ROUTES(lang).RESELLER_REDEEM}`

      break

    case "reseller_sign_in_page":
      route = `${ROUTES(lang).RESELLER_SIGN_IN}`

      break

    case "reseller_additional_info_page":
      route = `${ROUTES(lang).RESELLER_ADDITIONAL_INFO}`

      break

    case "federated_verification_page":
      route = `${ROUTES(lang).FEDERATED_VERIFICATION}`

      break

    case "partner_promotion_page":
      route = ROUTES(lang).PARTNER_PROMOTION

      break

    case "cookie_declaration_page":
      route = ROUTES(lang).COOKIE_DECLARATION

      break

    default:
      route = ROUTES(lang).SALES_LANDING

      break
  }

  return route
}

module.exports = {
  linkResolver,
}
