import * as React from "react"
import isEqual from "lodash/isEqual"
import type { SubscriptionType } from "../../types/SubscriptionType"
import type { LabelType } from "../../types/LabelType"

import Button from "../Button"
import {
  convertCentsToEuro,
  formatIntervalUnit,
  calculatePercentOffMonthlyPrice,
  isEmpty,
} from "../../utils/utilities"
import {
  formatPricePerMonth,
  needFormatPricePerMonth,
} from "../../utils/product"

import {
  getSubscriptionType,
  isZeroDecimalCurrency,
} from "../../utils/subscriptions"

import { CURRENCIES } from "../../constants/currency"

type Props = {|
  subscription: SubscriptionType,
  btnLabel: String,
  handleClickStartNow: () => void,
  classNames: String,
  prismicData: {
    annual_interval: Array<LabelType>,
    daily_interval: Array<LabelType>,
    monthly_inteval: Array<LabelType>,
  },
  disabled?: Boolean,
  monthlySubscription?: SubscriptionType,
  layoutData?: Object,
  isNZOffers?: Boolean,
  order?: number,
  isPromotion?: Boolean,
  isPartnerPromotion?: Boolean,
|}

const Subscription = ({
  subscription,
  btnLabel,
  handleClickStartNow,
  classNames,
  prismicData,
  disabled,
  monthlySubscription = {},
  layoutData = {},
  isNZOffers,
  order,
  isPromotion,
  isPartnerPromotion,
}: Props) => {
  const {
    currency,
    name,
    isBestValue,
    description,
    isPromotionOffer,
    isSpecialOffer,
    isRecurring,
  } = subscription
  const product_price_point = subscription.product_price_point || {}
  const {
    free_trial = {},
    after_trial = {},
    cancel_future_any_time = {},
    billed_price = {},
    subscription_unit = {},
    percent_off_monthly_price = {},
    promotion_after_trial = {},
    promotion_cancel_future_any_time = {},
    promotion_billed_price = {},
    promotion_free_trial = {},
    promotion_percent_off_monthly_price = {},
    promotion_unit = {},
  } = prismicData
  const { best_value = {} } = layoutData
  const {
    price_in_cents,
    trial_interval,
    trial_interval_unit,
    final_price_in_cents,
  } = product_price_point
  const freeTrialText = free_trial.text || promotion_free_trial.text
  const percentOffMonthlyText =
    percent_off_monthly_price.text || promotion_percent_off_monthly_price.text
  const afterTrialText = after_trial.text || promotion_after_trial.text
  const cancelFutureText =
    cancel_future_any_time.text || promotion_cancel_future_any_time.text
  const unit = formatIntervalUnit(product_price_point, layoutData)
  const currencySymbol = CURRENCIES[currency]

  // https://lesmillsinternational.atlassian.net/browse/LA-1444
  // Display price in /membership as a "per month" format in the US
  const needToFormatPricePerMonth = needFormatPricePerMonth(isNZOffers)
  let price = needToFormatPricePerMonth
    ? formatPricePerMonth(product_price_point)
    : // LA-1240: Show final_price_in_cents for Offer instead of price_in_cents
    isNZOffers
    ? final_price_in_cents
    : // for non US display as normal
      price_in_cents
  price = convertCentsToEuro(price, currency, 2)
  const totalPrice = convertCentsToEuro(
    isNZOffers ? final_price_in_cents : price_in_cents,
    currency,
    2
  )
  const percentOffMonthly =
    !isEmpty(monthlySubscription) &&
    calculatePercentOffMonthlyPrice(
      product_price_point,
      monthlySubscription,
      isPromotionOffer
    )
  const subscriptionType = getSubscriptionType(product_price_point, layoutData)

  const handleSaveSubscription = product => {
    handleClickStartNow(product)
  }
  const isNotRecurringOffer = isSpecialOffer && isRecurring === false

  return (
    <div
      className={classNames + " py-10 text-center subscription-item"}
      data-cy="subscription"
      style={{
        order,
      }}
    >
      <p
        className={`font-primary tracking-x-1.21 uppercase px-20 text-center ${
          isBestValue ? "text-red-600" : "text-gray-920"
        } ${isPromotion ? "text-3xl md:text-65l" : "text-65l"}`}
      >
        {/* LA-1339: Show name for special offer */}
        {`${isPromotion || isNZOffers ? name : subscriptionType}${
          isBestValue ? ` - ${best_value.text}` : ""
        }`}
      </p>
      <p className="text-gray-500 font-primary relative mr-60 price">
        <span className="tracking-x-3.08 lg:text-xxl sm:text-95l text-8xl text-gray-450">
          {currencySymbol}
        </span>
        <span className="lg:text-11xxl sm:text-85xl text-82xl tracking-x-7 text-gray-450 leading-115">
          {parseInt(price)}
        </span>
        {!isZeroDecimalCurrency(currency) && (
          <span className="tracking-x-3 lg:text-xxl sm:text-95l text-65l text-gray-45 absolute md:top-6 top-15 sm:top-10 ml-10">
            {price.substr(price.length - 3)}
          </span>
        )}
        {isNotRecurringOffer ? null : (
          <span className="font-base-light text-black lg:text-base text-2lg absolute lg:bottom-10 bottom-15 w-max-content lg:text-base text-2lg md:ml-10 sm:ml-5 ml-10">
            {/* https://lesmillsinternational.atlassian.net/browse/LA-1444
          Display price in /membership as a "per month" format in the US*/}
            {subscription_unit.text || promotion_unit.text}{" "}
            {needToFormatPricePerMonth ? layoutData.month.text : unit}
          </span>
        )}
      </p>
      <div
        className={`text-center flex flex-col justify-between mb-3 my-0 mx-auto lg:w-60/100 w-80/100${
          !isPromotionOffer ? " md:h-after-footer h-90" : ""
        }`}
      >
        {percentOffMonthly ? (
          <p className="text-red-600 sm:text-3xs lg:text-base text-2lg font-base-medium sm:ml-10">
            {percentOffMonthly}
            {percentOffMonthlyText}
          </p>
        ) : null}
        {trial_interval && !isPartnerPromotion ? (
          <p className="text-black sm:text-3xs lg:text-base text-2lg font-base-medium">
            {trial_interval} {layoutData[trial_interval_unit].text}{" "}
            {freeTrialText}
          </p>
        ) : null}
      </div>
      {/* LA-1339: Show description of special offer */}
      {isPromotionOffer ? (
        <p className="text-black text-3xs leading-22 lg:text-base font-base-light text-gray-800 md:text-2lg w-9/10 m-auto md:w-full my-10 mx-auto">
          {description}
        </p>
      ) : null}
      <Button
        className="uppercase btn btn-primary py-3 px-30 xl:px-30 lg:px-20 sm:px-0 min-w-209 my-4 text-2xl md:text-xl rounded-3 text-white font-primary sm:ml-20"
        handleOnClick={() => handleSaveSubscription(subscription)}
        disabled={disabled}
      >
        {btnLabel}
      </Button>
      <p className="text-black font-base-light text-3xs tracking-x-0.3 sm:ml-20">
        {isNotRecurringOffer ? null : (
          <span>
            {currencySymbol}
            {totalPrice} {billed_price.text || promotion_billed_price.text}{" "}
            {getSubscriptionType(
              product_price_point,
              layoutData,
              true
            ).toLowerCase()}{" "}
            {trial_interval ? ` ${afterTrialText}` : ""}
          </span>
        )}
        <span className="block">{cancelFutureText}</span>
      </p>
    </div>
  )
}

export default React.memo(Subscription, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.prismicData, nextProps.prismicData) &&
    isEqual(prevProps.subscription, nextProps.subscription)
  )
})
