import * as React from "react"
import { navigate } from "gatsby"
import { RichText } from "prismic-reactjs"

import { isBrowser } from "../../utils/localstorage"
import { linkResolver } from "../../utils/linkResolver"
import { htmlSerializerUpdateStyle } from "../../utils/prismic"

import Icon from "../Icon"

type Props = {|
  navs: Array<Object>,
  classNames?: Object,
  renderField?: string,
  lastChild?: React.Node,
  userLoggedIn?: Boolean,
  showSideBar?: Boolean,
|}

const Navigations = ({
  navs,
  classNames = {},
  renderField = "label",
  lastChild,
  userLoggedIn,
  showSideBar,
}: Props) => {
  const renderNavItem = link => {
    if (
      (link.id === "search-nav" || link.id === "faq-nav") &&
      userLoggedIn &&
      !showSideBar
    ) {
      return (
        <Icon
          icon={link.id}
          type="xl"
          classNames="w-7 bg-contain cursor-pointer md:ml-12"
          handleOnClick={() => navigate(link.link_url.text)}
        />
      )
    } else if (link.id !== "adidas-nav") {
      return (
        <RichText
          render={link[renderField].raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle()}
        />
      )
    }
  }
  return (
    <ul className={classNames.wrapper}>
      {navs.map((link, index) => (
        <li
          className={
            classNames.item +
            ` ${showSideBar ? "flex justify-between" : "flex"}`
          }
          key={`${link[renderField].text}-${index}`}
        >
          {isBrowser && renderNavItem(link)}
        </li>
      ))}
      {lastChild && (
        <li className={`${classNames.item} last-child-nav md:mt-0 md:pt-0`}>
          {lastChild}
        </li>
      )}
    </ul>
  )
}

export default React.memo(Navigations)
