// @flow
import * as React from "react"

type FormTitleProps = {|
  title: string,
  classNames: string,
  testId?: string,
|}

const FormTitle = ({ title, classNames = "", testId = "" }: FormTitleProps) => (
  <h2
    className={`text-gray-800 font-base-black text-6xl md:text-9xl ${classNames}`}
    data-cy={testId}
    data-testid={testId}
  >
    {title}
  </h2>
)

export default React.memo<FormTitleProps>(FormTitle)
