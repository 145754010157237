import React from "react"

import type { LabelType } from "../../types/LabelType"

import Textbox from "../Textbox"
import Button from "../Button"
import Icon from "../Icon"
import { getPrismicText } from "../../utils/prismic"

type PrismicData = {|
  promo_code_title: Array<LabelType>,
  apply_promo_code_button_label: Array<LabelType>,
  promo_code: Array<LabelType>,
  promo_code_applied: Array<LabelType>,
|}

type Props = {|
  data: PrismicData,
  classNames?: Object,
  togglePromoCode?: () => void | Promise<void>,
|}

const PromotionCode = ({
  data,
  classNames = {},
  togglePromoCode = () => {},
}: Props) => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const {
    promo_code_title = [],
    apply_promo_code_button_label = [],
    promo_code = [],
  } = data

  const handleSubmit = () => {
    // form validator
    setIsProcessing(true)
    // call API
  }

  return (
    <div className="text-left">
      <div className="flex justify-between mt-30">
        <h4
          className="font-base-black text-gray-400 lg:text-2lg text-base text-left"
          data-cy="promo-code-title"
        >
          {getPrismicText(promo_code_title)}
        </h4>
        <Icon
          icon="close"
          classNames="h-icon-base w-icon-base cursor-pointer"
          handleOnClick={() => togglePromoCode(false)}
        />
      </div>
      <div className="flex mt-20 lg:flex-row flex-col w-full">
        <Textbox
          label={getPrismicText(promo_code)}
          classNames={{
            wrapper: `mb-0 lg:mb-0 w-full ${
              classNames.textbox ? classNames.textbox : "lg:w-448"
            }`,
            textbox: "w-full h-textbox-base lg:h-textbox-lg lg:text-2lg",
            label: "lg:text-2lg",
          }}
          testId="promo-code-input"
          id="promotion-code"
          disabled={isProcessing}
        />
        <Button
          handleOnClick={() => handleSubmit()}
          disabled={isProcessing}
          submitting={isProcessing}
          className="btn btn-layout lg:w-btn-xs lg:h-btn-xs lg:ml-12 w-full h-btn-xxs mb-35"
        >
          {getPrismicText(apply_promo_code_button_label)}
        </Button>
      </div>
    </div>
  )
}

export default PromotionCode
