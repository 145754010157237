import * as React from "react"
import { RichText } from "prismic-reactjs"

import {
  orderProductsByPrice,
  getProductResponse,
  isMonthlyProduct,
} from "../../utils/product"
import {
  htmlSerializerUpdateStyle,
  handleErrorWithPrismic,
} from "../../utils/prismic"
import { linkResolver } from "../../utils/linkResolver"
import { USER_COUNTRY, setLocalStorage } from "../../utils/localstorage"
import {
  getProduct,
  setBestValueForSubscriptions,
} from "../../utils/subscriptions"

import { isEmpty } from "../../utils/utilities"

import { getLMNZOffer } from "../../graphql/getLMNZOffer"
import { getListProducts } from "../../graphql/getListProducts"

import type { SubscriptionType } from "../../types/SubscriptionType"

import ProgressBar from "../ProgressBar"
import Notification from "../Notification"
import Subscription from "./Subscription"

type Props = {|
  planNotes: Array,
  btnLabel: string,
  handleClickStartNow: () => void,
  classNames: {
    wrapper: String,
    subscriptions: String,
    item: String,
  },
  isOnMembership?: Boolean,
  prismicData: Object,
  selectedSubscription?: SubscriptionType,
  selectedBtn?: string,
  processing?: Boolean,
  isNZOffers?: Boolean,
  layoutData?: Object,
  getCurrentCountryCode?: () => void,
  productList?: Object,
|}

const Subscriptions = ({
  planNotes,
  btnLabel,
  classNames,
  handleClickStartNow,
  isOnMembership,
  prismicData = {},
  processing,
  layoutData = {},
  isNZOffers,
  getCurrentCountryCode = () => {},
  productList = {},
}: Props) => {
  const [loading, setLoading] = React.useState(0)
  const [subscriptions, setSubscriptions] = React.useState([])
  const [error, setError] = React.useState(null)
  const isHadProducts = !isEmpty(productList) && !isNZOffers

  const handleError = err => {
    // Handle error with prismic data
    handleErrorWithPrismic(
      err,
      prismicData.lmod_lpbc_err && prismicData.lmod_lpbc_err.text,
      setError,
      prismicData
    )
  }

  const handleProductList = data => {
    const res = getProductResponse(data, isNZOffers)
    const currentCountryCode =
      (data.data &&
        data.data.getValidProducts &&
        data.data.getValidProducts.countryCode.toLowerCase()) ||
      (data.data &&
        data.data.getLmnzOffer &&
        data.data.getLmnzOffer.country_code.toLowerCase())

    currentCountryCode && getCurrentCountryCode(currentCountryCode)

    if (isOnMembership) {
      // Save countryCode to prepopulate at billing information form
      setLocalStorage(USER_COUNTRY, res.countryCode || "")
    }

    const productsByPrice = orderProductsByPrice(res.products || [])
    setBestValueForSubscriptions(productsByPrice)
    setSubscriptions(productsByPrice)
  }

  React.useEffect(() => {
    if (isHadProducts) {
      handleProductList(productList)
    } else {
      getProduct(
        setLoading,
        isNZOffers ? getLMNZOffer : getListProducts,
        handleProductList,
        handleError
      )
    }
  }, [])

  const renderSubscriptions = () => {
    if (loading < 100 && !isHadProducts)
      return (
        <div className="mx-auto mb-20 mt-0 md:w-progress-bar w-9/10">
          <ProgressBar
            currentValue={loading}
            classNames={{ wrapper: " w-full" }}
          />
        </div>
      )

    if (error) return <Notification message={error.message} type="error" />

    const monthlySubscription = subscriptions.find(item =>
      isMonthlyProduct(item)
    )

    const len = subscriptions.length

    return (
      <>
        {subscriptions.map((item, index) => (
          <Subscription
            subscription={item}
            key={item.product_handle}
            btnLabel={btnLabel}
            classNames={classNames.item}
            handleClickStartNow={handleClickStartNow}
            prismicData={prismicData}
            isOnMembership={isOnMembership}
            disabled={processing}
            monthlySubscription={monthlySubscription}
            layoutData={layoutData}
            isNZOffers={isNZOffers}
            order={len - index}
          />
        ))}
      </>
    )
  }

  return (
    <section className={"mx-auto " + classNames.wrapper}>
      <div className={"flex flex-wrap " + classNames.subscriptions}>
        {renderSubscriptions()}
      </div>
      <RichText
        render={planNotes.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "text-center md:text-base font-base-light text-gray-800 md:leading-tight leading-3normal text-3xs md:pt-5"
        )}
      />
    </section>
  )
}
export default Subscriptions
