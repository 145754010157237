// @flow
import React, { useState, memo } from "react"
import Icon from "../Icon"
import Textbox from "../Textbox"

type Props = {|
  textbox?: Object,
  iconClassnames?: string,
  classNames?: Object,
|}

const PasswordTextbox = ({
  textbox = {
    handleOnChange: () => {},
    handleOnKeyPress: () => {},
    isError: false,
    value: "",
    label: "Password",
    defaultValue: "",
    inputRef: {},
    error: "",
    testId: "",
    id: "",
  },
  classNames = {},
  iconClassnames = "absolute md:top-15 right-17 top-10 w-6",
}: Props) => {
  const [type, setType] = useState("password")
  const icon = type === "password" ? "show-password" : "hide-password"

  return (
    <Textbox
      type={type}
      classNames={{
        textbox:
          "w-textbox-base h-textbox-base md:w-textbox-lg md:h-textbox-lg md:text-2lg",
        label: "md:text-2lg",
        ...classNames,
      }}
      {...textbox}
      icon={
        <Icon
          type="base"
          icon={icon}
          handleOnClick={() =>
            setType(type === "password" ? "text" : "password")
          }
          classNames={iconClassnames}
          testId="show-pwd-icon"
        />
      }
    />
  )
}

export default memo<Props>(PasswordTextbox)
