import * as React from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import type { QuestionType } from "../../types/QuestionType"

import Accordion from "../Accordion"

import { htmlSerializerUpdateStyle } from "../../utils/prismic"
import { isBrowser } from "../../utils/localstorage"
import { linkResolver } from "../../utils/linkResolver"

type Props = {|
  questions: Array<QuestionType>,
  classNames?: {
    main: Object,
    children: string,
    answers: string,
  },
  icon: string,
|}

const FrequentlyAskedQuestions = ({ questions, classNames, icon }: Props) => {
  const { main = {}, children, answers } = classNames || {}
  const questionClass = classNames.question
  const accordionList = questions.map(
    ({ question, answer, id, lastChild }, index) => {
      return {
        id,
        title: (
          <RichText
            render={question.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              questionClass,
              "",
              "",
              "",
              "",
              undefined, // This function was called after image was loaded
              "_self",
              "",
              "",
              "",
              questionClass
            )}
          />
        ),
        children: (
          <div
            className={`${children}${id ? " " + id : ""}`}
            data-cy={`answer-accordion-${index}`}
            data-testid={`answer-accordion-${index}`}
          >
            {isBrowser && answer && (
              <RichText
                render={answer.raw}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializerUpdateStyle("", answers)}
              />
            )}
            {lastChild}
          </div>
        ),
      }
    }
  )

  return (
    <Accordion
      list={accordionList}
      testId="question-accordion"
      classNames={main}
      icon={icon}
    />
  )
}

export default React.memo(FrequentlyAskedQuestions, (prevProps, nextProps) => {
  return isEqual(prevProps.questions, nextProps.questions)
})
