import { API, graphqlOperation } from "aws-amplify"

import { getProductById as getProductByIdQuery } from "@lesmills/gatsby-theme-account-management/src/graphql/getProductById"
import { getOfferByHandle as getOfferByHandleQuery } from "../graphql/getOfferByHandle"

import { SENTRY_ACTIONS } from "../constants/sentry-actions"

import { publicAPI } from "./publicAPI"
import {
  setLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  getSessionStorage,
  CURRENT_COUNTRY,
  USER_INFO_KEY,
  getLocalStorage,
} from "./localstorage"
import { UpdateUserCart } from "../graphql/updateUserCart"
import { captureException } from "./sentry"
import { updateMembership } from "../graphql/updateMembership"
import { navigate } from "gatsby"
import { ROUTES } from "../constants/routes"

export const formatUpdateUserCartInput = async (
  product,
  isDifferentCountry
) => {
  const {
    product_handle,
    offerId,
    transactionId,
    affiliateId,
    offer_handle,
  } = product
  const isAffiliate = !!offerId
  const currentCountry = await getSessionStorage(CURRENT_COUNTRY)
  let userInput = {
    cartProductHandle: product_handle,
    cartOfferId: isAffiliate ? offerId : null,
    cartAffiliateId: isAffiliate ? affiliateId : null,
    cartTransactionId: isAffiliate ? transactionId : null,
    cartExpiryDate: isAffiliate ? getOfferExpiryDate() : null,
    cartOfferHandle: offer_handle || null, // LA-1810: Promotion offer to go through the retail journey
  }

  // Make sure currentCountry has value
  if (isDifferentCountry && currentCountry) {
    userInput = {
      addressCountry: currentCountry,
      addressLocality: null,
      addressPostalCode: null,
      addressRegion: null,
      addressStreet: null,
      addressStreet2: null,
      addressStateCode: null,
      ...userInput,
    }
  }

  return userInput
}

export const updateUserCart = async (
  product,
  isDifferentCountry,
  callback = () => {},
  handleError = () => {}
) => {
  const userCartInput = await formatUpdateUserCartInput(
    product,
    isDifferentCountry
  )

  // AB2B-730: Prevent to update address without country
  if (
    !isDifferentCountry ||
    (isDifferentCountry && userCartInput.addressCountry)
  ) {
    try {
      await API.graphql(
        graphqlOperation(UpdateUserCart, {
          input: userCartInput,
        })
      )

      if (isDifferentCountry) {
        setLocalStorage(USER_INFO_KEY, {
          ...getLocalStorage(USER_INFO_KEY),
          ...userCartInput,
        })
      }

      callback()
    } catch (err) {
      const error = err.errors && err.errors[0]
      handleError(error)

      captureException({
        action: SENTRY_ACTIONS.UPDATE_USER_CART,
        requestVariables: {
          input: userCartInput,
        },
        ...err,
      })
    }
  }
}

export const isExpiredOffer = expiryDate => {
  const now = new Date().getTime()

  return expiryDate && expiryDate <= convertTimeStampToSeconds(now)
}

export const convertTimeStampToSeconds = timeStamp =>
  Math.ceil(timeStamp / 1000)

export const getOfferExpiryDate = () => {
  let now = new Date()

  const expiryDate = now.setDate(now.getDate() + 7)

  return convertTimeStampToSeconds(new Date(expiryDate).getTime())
}

export const getOfferByHandle = (user, handleNavigate, handleError) => {
  const {
    cartOfferHandle,
    cartOfferId,
    cartTransactionId,
    cartAffiliateId,
  } = user

  publicAPI(
    getOfferByHandleQuery,
    ({ data }) => {
      //https://lesmillsinternational.atlassian.net/browse/AB2B-905
      const offer =
        data.data.getOfferByHandleV2 && data.data.getOfferByHandleV2[0]

      if (offer) {
        // set offer data to local storage
        setLocalStorage(NEW_SUBSCRIPTION_KEY, {
          // LA-1666: Save OfferId, TransactionId, OfferHandle for post back to TUNE
          offerId: cartOfferId,
          transactionId: cartTransactionId,
          affiliateId: cartAffiliateId,
          offerHandle: cartOfferHandle,
          currency: offer.product_price_point.currency,
          ...offer,
        })
        // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-825
        // Abandoned cart - affiliate
        handleNavigate()
      } else {
        // check case getProduct = null
        handleError()
      }
    },
    err => {
      captureException({
        action: SENTRY_ACTIONS.GET_OFFER_BY_HANDLE,
        requestVariables: {
          input: {
            offer_handle: cartOfferHandle,
          },
        },
        ...err,
      })

      handleError(err)
    },
    {
      offer_handle: cartOfferHandle,
    }
  )
}

export const getProductById = async (user, handleNavigate, handleError) => {
  // Check to avoid call api with productId null
  if (user.cartProductHandle) {
    const input = {
      productId: user.cartProductHandle,
      ...(user.addressCountry
        ? {
            countryCode: user.addressCountry,
          }
        : {}),
    }

    try {
      const res = await API.graphql(
        graphqlOperation(getProductByIdQuery, {
          ...input,
        })
      )

      if (res.data.getProduct) {
        // set product to local storage
        setLocalStorage(NEW_SUBSCRIPTION_KEY, {
          ...res.data.getProduct,
        })

        // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-826
        // Abandoned cart - retail
        handleNavigate()
      } else {
        handleError() // check case getProduct = null
      }
    } catch (error) {
      captureException({
        action: SENTRY_ACTIONS.GET_PRODUCT_BY_ID,
        requestVariables: {
          input,
        },
        ...error,
      })

      handleError(error)
    }
  } else {
    navigate(ROUTES(lang).MEMBERSHIP)
  }
}

export const getCartProduct = (user, handleNavigate, handleError) => {
  const { cartOfferHandle } = user

  // LA-1664: get offer by offer_handle in stead of offer_id if affiliate
  if (cartOfferHandle) {
    getOfferByHandle(user, handleNavigate, handleError)
  } else {
    // get product by Id if retail user
    getProductById(user, handleNavigate, handleError)
  }
}

export const updateMembershipInfo = async (
  input,
  callback = () => {},
  handleError = () => {}
) => {
  try {
    await API.graphql(
      graphqlOperation(updateMembership, {
        input: {
          membershipNo: input.membershipNumber,
          gymLocation: input.gym,
        },
      })
    )

    callback()
  } catch (err) {
    const error = err.errors && err.errors[0]

    error && handleError(error.errorType)

    captureException({
      action: SENTRY_ACTIONS.UPDATE_USER_CART,
      requestVariables: {
        input,
      },
      ...err,
    })
  }
}
