import React from "react"

type CheckboxProps = {|
  handleOnChange?: () => void | Promise<void>,
  name?: string,
  label?: string,
  styles?: Object,
  checked?: Boolean,
  testId?: string,
  disabled?: boolean,
  checkboxRef?: {
    current: {
      checked: boolean,
    },
  },
  defaultChecked?: boolean,
|}

const Checkbox = ({
  name,
  label,
  checked,
  handleOnChange,
  styles = {},
  testId,
  disabled,
  checkboxRef,
  defaultChecked,
}: CheckboxProps) => (
  <label
    className={`${styles.wrapper ? styles.wrapper : ""} ${
      disabled ? "disabled" : "cursor-pointer"
    }`}
  >
    {label}
    <input
      type="checkbox"
      name={name}
      onChange={handleOnChange}
      checked={checked}
      className={styles.checkbox ? styles.checkbox : ""}
      data-cy={testId}
      disabled={disabled}
      ref={checkboxRef}
      defaultChecked={defaultChecked}
    />
    <span className={styles.checkmark ? styles.checkmark : ""} />
  </label>
)

export default React.memo(Checkbox)
