import React from "react"

type SpinnerProps = {|
  type?: string,
  className?: string,
  isDarkTheme?: boolean,
|}

const Spinner = ({
  type,
  className = "",
  isDarkTheme = false,
}: SpinnerProps) => (
  <div
    className={`w-full h-full absolute flex top-0 left-0 z-50 justify-center items-center spinner-wrapper ${className}`}
  >
    <div
      className={`loader-animation ease-linear rounded-full ${
        isDarkTheme
          ? "border-gray-800 w-avatar h-avatar border-7 border-t-7"
          : "border-gray-75 h-8 w-8 border-4 border-t-4"
      } ${type === "primary" ? "primary-loader" : "default-loader"}`}
    />
  </div>
)

export default Spinner
