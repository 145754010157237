// @flow
import React from "react"
import { Link } from "gatsby"

type Props = {|
  title: string,
  linkLabel: string,
  classNames?: Object,
  url: string,
  id?: string,
|}

const Toast = ({
  title,
  linkLabel,
  url,
  classNames = { wrapper: "", info: "", link: "" },
  id = "",
}: Props) => {
  return (
    <div
      className={`bg-white flex flex-row justify-center items-center pt-15 pr-20 pb-15 pl-20 w-full z-50 shadow-sm md:shadow ${classNames.wrapper}`}
      data-testid="ToastElement"
      data-cy="ToastElement"
      id={id}
    >
      <p
        className={
          classNames.info
            ? `uppercase font-base-light text-base text-center md:text-2lg font-bold leading-none leading-4none md:leading-2none mr-20 md:mr-43 md:ls-0.69 ${classNames.info}`
            : `uppercase font-base-light text-base text-center md:text-2lg font-bold leading-none leading-4none md:leading-2none mr-20 md:mr-43 md:ls-0.69`
        }
      >
        {title}
      </p>
      <Link
        to={url}
        className={
          classNames.link
            ? `no-underline btn bg-orange-400 hover:bg-black hover:text-white font-primary text-2sm font-bold pt-10 pb-10 w-toast-btn-small md:w-auto md:px-20 md:pt-12 md:pb-12 md:pl-40 md:pr-40 leading-2none ls-0.69 ${classNames.link}`
            : `no-underline btn bg-orange-400 hover:bg-black hover:text-white font-primary text-2sm font-bold pt-10 pb-10 w-toast-btn-small md:w-auto md:px-20 md:pt-12 md:pb-12 md:pl-40 md:pr-40 leading-2none ls-0.69`
        }
      >
        {linkLabel}
      </Link>
    </div>
  )
}

export default Toast
