import React, { useState, useEffect } from "react"
import {
  redirectTo,
  setSessionStorage,
  getSessionStorage,
  CURRENT_COUNTRY,
} from "../../utils/localstorage"
import { AXII_CONFIGS } from "../../../../../src/configs/axii-config"
import { ROUTES } from "../../constants/routes"
import { areCountriesSpecificPages } from "../../utils/redirection"
import { getHeaders } from "../../graphql/getHeaders"
import { publicAPI } from "../../utils/publicAPI"
import LoadingIndicator from "../LoadingIndicator"

// // Types
import type { PubicRouteProps, PrivateRouteProps } from "../../types/RouteProps"

import { getCookie } from "../../utils/utilities"

/**
 * This HOC is used to getHeaders
 * Then check to redirect to Vimeo Page for not enable countries
 * @param {*} Component
 */
export const withCheckEnableCountries = Component => {
  const HocComponent = (props: PubicRouteProps | PrivateRouteProps) => {
    const { pathname = "" } = props.location || {}
    const [fetchedHeaders, setFetchedHeaders] = useState(false)
    const isPreviewMode = getCookie()["io.prismic.preview"]

    // AD2C-31
    if (
      pathname === ROUTES(props.lang).SALES_LANDING ||
      pathname.includes(ROUTES(props.lang).MEMBERSHIP) ||
      pathname === ROUTES(props.lang).FEDERATED_VERIFICATION
    ) {
      return <Component {...props} />
    }

    const mustCheckEnableCountries =
      (process.env.GATSBY_E2E === true || process.env.GATSBY_E2E !== "true") &&
      !isPreviewMode &&
      !!pathname &&
      areCountriesSpecificPages(pathname, props.lang)

    // Call back after getting enable countries
    const callBackAfterGetEnabledCountries = async response => {
      const isDataFromStorage = typeof response === "string" // Check in case data from sessionStorage
      const countryCode = isDataFromStorage
        ? response
        : response.data.data.getHeaders.countryCode.toLowerCase()

      // With enabled countries can go through Axii pages
      const isEnabledCountries =
        AXII_CONFIGS.enabledCountries.indexOf(countryCode.toLowerCase()) > -1

      // Save country code to session localstorage to avoid refetch
      if (!isDataFromStorage) {
        setSessionStorage(CURRENT_COUNTRY, countryCode)
      }

      // Without enabled countries, user can be navigated to watch.lesmillsondemand.com
      // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-171
      if (!isEnabledCountries) {
        /**
         * 1 - Only people in AXII enabled country can sign up through AXII
         * 2 - All pages that are included in the sign-up flow will be restricted to AXII’s country(ies) only. If people are not from AXII’s countries, then redirect to watch.lesmillsondemand.com
         * 3 - If people come to our landing page from other territories, then re-direct to watch.lesmillsondemand.com so they can subscribe through Magento
         */
        redirectTo(ROUTES().LMOD_CUSTOMER_WATCH)
        return
      } else {
        setFetchedHeaders(true)
      }
    }

    useEffect(() => {
      // Should check from localStorage first, if it is existing country code
      // No need to refetch
      // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-955
      if (mustCheckEnableCountries) {
        // Get country code from session Storage
        const sessionStorageData = getSessionStorage(CURRENT_COUNTRY)
        if (!sessionStorageData) {
          publicAPI(
            getHeaders,

            // Call back after get headers api
            callBackAfterGetEnabledCountries,

            () => {
              // This callback will be called if there are any error
              setFetchedHeaders(true)
            }
          )
        } else {
          // Check to navigate with country code from session storage
          callBackAfterGetEnabledCountries(sessionStorageData)
        }
      }
    }, [])

    if (mustCheckEnableCountries && !fetchedHeaders) {
      return <LoadingIndicator isDarkTheme />
    }

    return <Component {...props} />
  }

  return HocComponent
}
