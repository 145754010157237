// @flow
import React from "react"

import { Icon } from "@lesmills/gatsby-theme-common"

type Step = {|
  name: string,
  completed?: Boolean,
  active?: Boolean,
|}

type Props = {|
  steps: Array<Step>,
  classNames?: string,
|}

const ProgressSteps = ({ steps, classNames = "" }: Props) => {
  return (
    <div className={classNames}>
      <ul className={"progress-steps"}>
        {steps.map((item, index) => {
          return (
            <li
              className={
                item.completed ? "completed" : item.active ? "active" : ""
              }
              key={index}
            >
              {item.completed && (
                // <i className="fa fa-check fa-lg light step-checkmark"></i>
                <Icon icon="tick" type="base" classNames="step-checkmark" />
              )}
              <span>{item.name}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ProgressSteps
