// @flow
import * as React from "react"

type Props = {|
  children: React.Node,
  className?: string,
  togglePopover: () => void,
|}

const Popover = ({ className = "", togglePopover, children }: Props) => {
  const popoverRef = React.useRef(null)

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick, false)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick, false)
    }
  }, [])

  const handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (popoverRef.current && popoverRef.current.contains(e.target)) {
      return
    }
    togglePopover(false)
  }

  return (
    <div ref={popoverRef} className={className}>
      {children}
    </div>
  )
}

export default Popover
