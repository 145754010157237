import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"

import { linkResolver, LoadingIndicator } from "@lesmills/gatsby-theme-common"

type Props = {|
  isPreview: Boolean,
|}

const PreviewPage = ({ isPreview }: Props) => {
  if (isPreview === false) return "Not a preview!"

  return <LoadingIndicator />
}

export default props => {
  return withPreviewResolver(PreviewPage, {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: () => doc => linkResolver(doc),
  })(props)
}
