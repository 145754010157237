import React from "react"
import PromotionCodeField from "./PromotionCode"

import type { LabelType } from "../../types/LabelType"

import Button from "../Button"
import { getPrismicText } from "../../utils/prismic"

type PrismicData = {|
  promo_code_title: Array<LabelType>,
  apply_promo_code_button_label: Array<LabelType>,
  promo_code: Array<LabelType>,
  promo_code_applied: Array<LabelType>,
  have_promo_code: Array<LabelType>,
|}

type Props = {|
  data: PrismicData,
  classNames?: Object,
  disabled?: boolean,
|}

const PromotionCode = ({ data, classNames = {}, disabled }: Props) => {
  const [isShowPromoCode, togglePromoCode] = React.useState(false)
  return (
    <>
      {isShowPromoCode ? (
        <PromotionCodeField
          data={data}
          classNames={classNames.promoCode}
          togglePromoCode={togglePromoCode}
        />
      ) : (
        <Button
          className="underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800  mt-26 mb-45 cursor-pointer"
          handleOnClick={() => togglePromoCode(true)}
          disabled={disabled}
        >
          {getPrismicText(data.have_promo_code)}
        </Button>
      )}
    </>
  )
}

export default PromotionCode
