// @flow
import React, { useState, useRef } from "react"
import Icon from "../Icon"

type Props = {|
  label: string,
  content: string,
  direction: "top" | "left" | "bottom" | "right",
  classNames?: Object,
|}

const Tooltip = ({ label, content, direction, classNames = {} }: Props) => {
  const [isOpenTooltip, toggleTooltip] = useState(true)
  const tooltipRef = useRef(null)

  // TODO: Handle outside click tooltip if require
  // useEffect(() => {
  //   document.addEventListener("click", handleOutsideClick, false)
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick, false)
  //   }
  // }, [])

  // const handleOutsideClick = e => {
  //   // ignore clicks on the component itself
  //   if (tooltipRef.current && tooltipRef.current.contains(e.target)) {
  //     return
  //   }
  //   toggleTooltip(false)
  // }

  return (
    <div
      className={`tooltip${classNames.wrapper ? classNames.wrapper : ""}`}
      data-direction={direction}
    >
      <div
        className={`tooltip__initiator${
          classNames.label ? classNames.label : ""
        }`}
      >
        {label}
      </div>
      {isOpenTooltip && (
        <div
          ref={tooltipRef}
          className={`tooltip__item${
            classNames.content ? classNames.content : ""
          }`}
        >
          <p>
            <span>{content}</span>
            <Icon
              icon="close"
              classNames="h-icon-base w-icon-base cursor-pointer ml-30 -mb-1"
              handleOnClick={() => toggleTooltip(!isOpenTooltip)}
            />
          </p>
        </div>
      )}
    </div>
  )
}

export default Tooltip
