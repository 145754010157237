import React from "react"
import Header from "./Header"
import Body from "./Body"
import isEqual from "lodash/isEqual"

type Props = {|
  headers: Array<String>,
  rows: Array<React.Node>,
  rowClassNames: String,
|}

const Table = ({ headers, rows, rowClassNames }: Props) => {
  return (
    <>
      <Header headers={headers} />
      <Body headers={headers} rows={rows} rowClassNames={rowClassNames} />
    </>
  )
}

export default React.memo(Table, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.rows, nextProps.rows) &&
    isEqual(prevProps.headers, nextProps.headers)
  )
})
