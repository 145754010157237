// @flow
import React, { memo } from "react"

type IconProps = {|
  icon: string,
  type?: string,
  handleOnClick?: () => void | Promise<void>,
  classNames?: string,
|}

const Icon = ({
  classNames = "",
  type = "",
  icon,
  handleOnClick,
}: IconProps) => (
  <span
    onClick={handleOnClick}
    className={`icon icon-${type} ${icon}-icon ${classNames} bg-no-repeat`}
    role="button"
  />
)

export default memo<IconProps>(Icon)
