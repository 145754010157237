export const GetUser = `
  query getUser {
    getUser {
      signupChannel
      userId
      type
      vimeoUserId
      email
      givenName
      familyName
      subComms
      subCommsConfirmed
      addressStreet
      addressStreet2
      addressCountry
      addressPostalCode
      addressRegion
      addressStateCode
      addressLocality
      userEntitlements {
        LMOD {
          vimeoUserTicket
        }
      }
      cartProductHandle
      cartOfferId
      cartOfferHandle
      cartAffiliateId
      cartExpiryDate
      cartTransactionId
      paymentMethod {
        last4
        cardType
        email
        type
      }
      chargifyPaymentProfile {
        last4
        cardType
        email
        type
      }
      isForceUpdateAddress
      isEntitledToFreeTrial
      lmodSubscription {
        user_id
        tune_offer_id
        offer_id
        product_handle
        offer_handle
        coupon_code
        total_price_in_cents
        promotion_label
        subscription_id
        preview_renewal {
          totalInCentsTaxInclusive
          totalInCentsTaxExclusive
        }
        product {
          product_handle
          name
          description
          currency
          rollover_product_handle
          tier {
            internal_tier_id
          }
          product_price_point {
            price_in_cents
            interval
            interval_unit
            trial_interval
            trial_interval_unit
            final_price_in_cents
            tax_in_cents
            final_tax_in_cents
          }
        }
        state
        previous_state
        reactive
        current_period_ends_at
        canceled_at
        next_product_id
        next_product_handle
        delayed_cancel_at
        default_to_monthly
        rollover_status
        subscription_type
      }
      hasOtherActiveSub
      chargifyUserId
    }
  }
`

// Using this query to avoid loading fetching at login page
export const GetUserSubscription = `
  query getUser {
    getUser {
      userEntitlements {
        LMOD {
          vimeoUserTicket
        }
      }
      vimeoUserId
      paymentMethod {
        email
      }
      lmodSubscription {
        canceled_at
      }
    }
  }
`
// Using this query for SSO
export const GetVimeoUserTicket = `
  query getUser {
    getUser {
      userEntitlements {
        LMOD {
          vimeoUserTicket
        }
      }
      vimeoUserId
    }
  }
`

export const GetUserWithoutPayment = `
  query getUser {
    getUser {
      signupChannel
      userId
      type
      vimeoUserId
      email
      givenName
      familyName
      addressStreet
      addressStreet2
      addressCountry
      addressPostalCode
      addressStateCode
      addressRegion
      addressLocality
      userEntitlements {
        LMOD {
          vimeoUserTicket
        }
      }
      cartProductHandle
      cartOfferId
      cartAffiliateId
      cartExpiryDate
      cartTransactionId
      cartOfferHandle
      subComms
      isForceUpdateAddress
      isEntitledToFreeTrial
      lmodSubscription {
        user_id
        tune_offer_id
        offer_id
        product_handle
        offer_handle
        total_price_in_cents
        promotion_label
        subscription_type
        product {
          product_handle
          name
          description
          currency
          rollover_product_handle
          product_price_point {
            price_in_cents
            interval
            interval_unit
            trial_interval
            trial_interval_unit
            final_price_in_cents
            tax_in_cents
            final_tax_in_cents
          }
          tier {
            internal_tier_id
          }
        }
        state
        previous_state
        reactive
        current_period_ends_at
        canceled_at
        next_product_id
        next_product_handle
        delayed_cancel_at
        default_to_monthly
        rollover_status
      }
      hasOtherActiveSub
    }
  }
`

export const GetUserSubcomms = `
  query getUser {
    getUser {
      subComms
      subCommsConfirmed
    }
  }
`

export const GetUserPayment = `
  query getUser {
    getUser {
      paymentMethod {
        last4
        cardType
        email
        type
      }
      chargifyPaymentProfile {
        last4
        cardType
        email
        type
      }
    }
  }
`
// Need full data as getUser, only add more fields for Challenges
export const GetUserChallengesSummary = `
  query getUser {
    getUser {
      userId
      type
      vimeoUserId
      email
      givenName
      familyName
      signupChannel
      lmodEntitlement
      lmodSubscription {
        user_id
        tune_offer_id
        offer_id
        product_handle
        product {
          product_handle
          name
          tier {
            internal_tier_id
          }
          description
          currency
          rollover_product_handle
          product_price_point {
            price_in_cents
            interval
            interval_unit
            trial_interval
            trial_interval_unit
            final_price_in_cents
            tax_in_cents
            final_tax_in_cents
          }
        }
        state
        previous_state
      }
      challengeEngagements {
        areChallengeEmailsSubscribed
        activeChallengeEngagement {
          challengeId
          startDate
          acceptedAt
          leftAt
          switchedFrom
        }
        pastChallengeEngagements {
          challengeId
          startDate
          acceptedAt
          leftAt
          switchedFrom
        }
      }
    }
  }
`
// Get user for check redirection after sign in
export const GetUserRedirectSignedIn = `
  query getUser {
    getUser {
      lmodEntitlement
      lmodSubscription {
        state
      }
      userEntitlements {
        LMOD {
          vimeoUserTicket
        }
      }
      vimeoUserId
    }
  }
`
