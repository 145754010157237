import React from "react"
import { linkResolver } from "./linkResolver"
import { Elements } from "prismic-reactjs"
import { captureException } from "./sentry"
import { addTicketToExternalLink } from "./redirection"
import { isBrowser } from "./localstorage"
import { AXII_CONFIGS } from "../../../../src/configs/axii-config"

export const getPrismicText = (field = [], index = 0) => {
  const len = field ? field.length : 0
  return len > 0 && index <= len ? field[index].text : ""
}

// Add unique key to props
export const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key })
}

export const formatPrismicBody = (data = []) => {
  const len = data.length
  const result = {}
  for (let i = 0; i < len; i++) {
    const body = data[i]
    result[body.type] = {}
    result[body.type].items = body.fields
    result[body.type].primary = body.primary
  }

  return result
}

export const formatRichTextLink = (
  richText,
  className,
  testId,
  forceTargetLink = "_self"
) => {
  const text = getPrismicText(richText)
  const spans = richText && richText[0] ? richText[0].spans : []
  const link = spans[0] || {}

  if (link.type === "hyperlink") {
    const linkData = link.data || {}
    if (linkData.link_type === "Document") {
      return (
        <a
          href={linkResolver(linkData)}
          className={className}
          target={forceTargetLink}
          data-cy={testId}
        >
          {text}
        </a>
      )
    }
    if (linkData.link_type === "Web") {
      return (
        isBrowser && (
          <a
            href={addTicketToExternalLink(linkData.url)}
            target={linkData.target}
            className={className}
            data-cy={testId}
          >
            {text}
          </a>
        )
      )
    }
  }
  return text
}

export const formatPrismicCountries = countries =>
  countries.map(({ country_code = {}, country_name = {} }) => ({
    value: country_code.text,
    name: country_name.text,
  }))

export const getPrismicNode = (data = {}, type = "") => {
  const page = data[type]

  return page && page.edges && page.edges.length > 0 && page.edges[0].node
}

// Handle error with default message and get data from prismic
export const handleErrorWithPrismic = (
  err,
  defaultMsg,
  callback,
  prismicData,
  action = "",
  isGetErrorCode = false, // save error code instead of message to use in RichText
  requestVariables = {} // requestVariables for capture error sentry
) => {
  if (err && err.length > 0) {
    // Send error to sentry
    if (action) {
      captureException({
        action,
        requestVariables: { ...requestVariables },
        ...err[0],
      })
    }

    // Get error type from first error if any
    const prismicDataError = prismicData[err[0].errorType]

    // Get message text or keep error code from Prismic data
    defaultMsg = !prismicDataError
      ? defaultMsg
      : isGetErrorCode
      ? prismicDataError
      : prismicDataError.text
  }

  if (err) {
    callback({
      type: "error",
      message: defaultMsg,
    })
  }
}

export const formatRichTextElementProps = (
  children,
  forceTargetLink,
  paramUrl
) =>
  children.map((item, index) => {
    if (item && item.props && item.props.href) {
      const href = addTicketToExternalLink(item.props.href)

      const propsWithTargetLink = {
        ...item.props,
        ...(forceTargetLink !== "_self" && !item.props.target
          ? {
              target: forceTargetLink,
            }
          : {}),
        href: paramUrl ? href + paramUrl : href,
      }
      children[index] = {
        ...item,
        props: { ...propsWithTargetLink },
      }
    }
  })

// htmlSerializer
export const htmlSerializerUpdateStyle = (
  imgStyle,
  paragraphStyle,
  listStyle,
  listItemStyle,
  paramUrl,
  targetLink,
  onLoadedImage = () => {}, // This function was called after image was loaded
  forceTargetLink = "_self",
  oListStyled = "list-decimal pl-40",
  preformattedStyle = "",
  heading2Styles = "",
  heading3Styles = ""
) => {
  const htmlSerializer = (type, element, content, children, key) => {
    let props = {}
    let link = element.linkTo

    switch (type) {
      case Elements.image:
        props = {
          src: element.url,
          alt: element.alt || "",
          className: `${element.alt === "programs" ? "w-full" : imgStyle}`,
          onLoad: onLoadedImage,
        }
        if (link) {
          switch (link.link_type) {
            case "Document":
              return (
                <a
                  href={
                    paramUrl
                      ? `${linkResolver(link)}${paramUrl}`
                      : linkResolver(link)
                  }
                  target={targetLink ? targetLink : "_self"}
                  key={`link-document-${element.alt}`}
                >
                  {React.createElement("img", propsWithUniqueKey(props, key))}
                </a>
              )
            case "Web":
              return (
                isBrowser && (
                  <a
                    href={addTicketToExternalLink(link.url)}
                    target={link.target}
                    key={`link-web-${element.alt}`}
                  >
                    {React.createElement("img", propsWithUniqueKey(props, key))}
                  </a>
                )
              )
            default:
              return null
          }
        }
        return React.createElement("img", propsWithUniqueKey(props, key))
      case Elements.paragraph:
        props = { className: paragraphStyle }
        // Force add target for link included in paragraph
        formatRichTextElementProps(children, forceTargetLink, paramUrl)

        return React.createElement(
          "p",
          propsWithUniqueKey(props, key),
          children
        )

      case Elements.heading2: // Heading 2
        props = { className: heading2Styles }
        return React.createElement(
          "h2",
          propsWithUniqueKey(props, key),
          children
        )

      case Elements.heading3: // Heading 3
        props = { className: heading3Styles }

        return React.createElement(
          "h3",
          propsWithUniqueKey(props, key),
          children
        )

      case Elements.list: // Unordered List
        props = { className: listStyle }
        return React.createElement(
          "ul",
          propsWithUniqueKey(props, key),
          children
        )

      case Elements.oList: // Ordered List
        props = { className: oListStyled }
        return React.createElement(
          "ol",
          propsWithUniqueKey(props, key),
          children
        )

      case Elements.listItem: // Unordered List Item
      case Elements.oListItem: // Ordered List Item
        props = { className: listItemStyle }
        formatRichTextElementProps(children, forceTargetLink, paramUrl)

        return React.createElement(
          "li",
          propsWithUniqueKey(props, key),
          children
        )
      case Elements.preformatted: // Preformatted
        props = { className: preformattedStyle || paragraphStyle }
        return React.createElement(
          "pre",
          propsWithUniqueKey(props, key),
          children
        )

      default:
        return null
    }
  }
  return htmlSerializer
}

export const getBodyDataByType = (body = [], type = "") =>
  body.filter(item => item.slice_type === type)

export const formatPrismicStates = selectedCountry => {
  const states = selectedCountry.items ? selectedCountry.items : []

  return states
    .map(item => {
      const state_code = item.state_code && item.state_code.text
      const state_name = item.state_name && item.state_name.text

      // This will avoid null data from Prismic
      if (!state_code) {
        return null
      }

      return {
        state_code,
        state_name,
        name: state_name,
        label: `${state_code} - ${state_name}`,
        state_short_name: item.state_short_name.text,
      }
    })
    .filter(item => !!item)
}

export const formatFrequentlyAskedQuestions = questions =>
  questions.map(item => {
    const answers = item.answer || []

    return {
      question: getPrismicText(item.question),
      answers: answers.map(ans => ({ text: ans.text })),
    }
  })

// https://lesmillsinternational.atlassian.net/browse/LA-1670
// Show incl. VAT in countries of EU, show incl. Tax in others
export const getIncTaxText = (country, { inc_tax = [], inc_vat = [] }) => {
  return !!country &&
    AXII_CONFIGS.euCountries.indexOf(country.toLowerCase()) > -1
    ? inc_vat.text
    : inc_tax.text
}

export const isExistDataSection = data => {
  for (const value of Object.values(data)) {
    if (
      (value.text && value.text !== "") || // text field
      (value.url && value.url !== "") || // image field
      (value.raw && value.raw.url && value.raw.url !== "")
    ) {
      // media field
      return true
    }
  }
  return false
}
