export const getLocation = `
  query getLocation(
    $country: String!, 
    $postalCode: String, 
    $addressStreet: String, 
    $state: String,
    $language: String
  ) {
    getLocation(
      country: $country, 
      postalCode: $postalCode, 
      addressStreet: $addressStreet, 
      state: $state,
      language: $language,
    ) {
      address
      postalCode
      city
      state
      country
      stateCode
      countryCode
    }
  }
`
