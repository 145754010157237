import * as React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

type Props = {|
  element: React.Node,
|}

export const wrapRootElement = ({ element }: Props) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_SITE_KEY_V3}>
    <PreviewStoreProvider initialEnabled={true}>{element}</PreviewStoreProvider>
  </GoogleReCaptchaProvider>
)
