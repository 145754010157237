export const handleClickOutSideRecaptcha = (
  onClickOutsideRecaptcha = () => {}
) => {
  // Find the open reCaptcha iframe
  HTMLCollection.prototype.find = Array.prototype.find
  const recaptchaIframe = document
    .getElementsByTagName("iframe")
    .find(
      elementIFrame =>
        elementIFrame.src &&
        elementIFrame.src.includes("google.com/recaptcha/api2/bframe")
    )
  if (recaptchaIframe) {
    // Find the reCaptcha window
    let recaptchaWindow = recaptchaIframe.parentNode.parentNode

    // Listen event closed iframe by click outside reCaptcha
    new MutationObserver(
      () =>
        recaptchaWindow.style.visibility === "hidden" &&
        !window.grecaptcha.getResponse() &&
        onClickOutsideRecaptcha()
    ).observe(recaptchaWindow, { attributes: true, attributeFilter: ["style"] })
  }
}
