import blackLogo from "../assets/images/black-logo.svg"
import whiteLogo from "../assets/images/white-logo.svg"
import downArrow from "../assets/images/down-arrow.svg"
import downArrowMedium from "../assets/images/down-arrow-md.svg"
import downArrowSmall from "../assets/images/down-arrow-sm.svg"
import largeAlert from "../assets/icons/large-alert.svg"
import blackLogoLmp from "../assets/images/black-logo-lmp.svg"
import whiteLogoLmp from "../assets/images/white-logo-lmp.svg"
import headerLogoLmi from "../assets/images/header-logo.svg"

export const images = {
  blackLogo,
  whiteLogo,
  downArrow,
  downArrowMedium,
  largeAlert,
  downArrowSmall,
  blackLogoLmp,
  whiteLogoLmp,
  headerLogoLmi,
}
