import React from "react"
import isEqual from "lodash/isEqual"

type Props = {|
  headers: Array<String>,
|}

const Header = ({ headers }: Props) => (
  <div className="hidden md:flex border-b border-gray-550 py-40">
    {headers.map(column => (
      <div
        key={column.id}
        className={
          "leading-18 font-base-black text-2lg text-gray-800 " +
          column.classNames
        }
      >
        {column.name}
      </div>
    ))}
  </div>
)

export default React.memo(Header, (prevProps, nextProps) => {
  return isEqual(prevProps.headers, nextProps.headers)
})
