export const getLMNZOffer = `
query getLmnzOfferV2{
  getLmnzOfferV2 {
    offer_handle
    product_handle
    product_name
    name
    description
    country_code
    tune_offer_id
    tune_affiliate_id
    product_price_point {
      price_in_cents
      interval
      interval_unit
      trial_price_in_cents
      trial_interval
      trial_interval_unit
      final_price_in_cents
      currency
    }
  }
}`
