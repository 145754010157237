export const SENTRY_ACTIONS = {
  // User - account
  SIGN_UP: "signUp",
  RESELLER_SIGN_UP: "resellerSignUp",
  UPDATE_USER: "updateUser",

  // Subscription
  CREATE_AFFILIATE_SUBSCRIPTION: "createAffiliateSubscription",
  CREATE_SUBSCRIPTION: "createSubscription",
  CHANGE_SUBSCRIPTION: "changeSubscription",
  CHANGE_SUBSCRIPTION_CHARGIFY_JS: "changeSubscriptionChargifyJs",
  CANCEL_SUBSCRIPTION: "cancelSubscription",
  RESTART_SUBSCRIPTION: "restartSubscription",
  DELETE_SUBSCRIPTION: "deleteSubscription",
  UPDATE_SUBSCRIPTION: "updateSubscription",

  // Chargify dropin
  GET_SERCURITY_CHARGIFY_TOKEN: "getSercurityChargifyToken",
  VERIFY_CHARGIFY_CREDIT_CARD: "verifyChargifyCreditCard",
  VERIFY_THREE_D_SECURE_CHARGIFY_CREDIT_CARD: "verify3DSChargifyCreditCard",
  VERIFY_CHARGIFY_PAYPAL: "verifyChargifyPaypal",
  THREE_D_SECURE: "3DSecure",
  THREE_D_SECURE_CONFIG: "3DSecureConfig",
  PAYPAL_ERROR: "paypalError",

  // Payment
  UPDATE_PAYMENT_DETAIL: "updatePaymentDetail",
  UPDATE_PAYMENT_CHARGIFY: "updatePaymentChargify",

  // Offer
  GET_OFFER_BY_ID: "getOfferById",
  UPDATE_USER_CART: "updateUserCart",
  GET_OFFER_BY_HANDLE: "getOfferByHandle",

  // Validate Vimeo account
  VALIDATE_VIMEO_ACCOUNT: "validateVimeoAccount",

  // Authentication
  CHANGE_PASSWORD: "changePassword",
  CURRENT_AUTHENTICATE_USER: "currentAuthenticatedUser",
  SIGN_OUT: "signOut",
  SIGN_IN: "signIn",
  FORGOT_PASSWORD: "forgotPassword",
  FORGOT_PASSWORD_SUBMIT: "forgotPasswordSubmit",
  CURRENT_SESSION: "currentSession",

  // Get Location
  GET_LOCATION: "getLocation",
  GET_PLACE_DETAIL: "getPlaceDetails",
  GET_PLACE_AUTO_COMPLETE: "getPlaceAutoComplete",

  // LMNZ Discount:
  UPDATE_MEMBERSHIP: "updateMembership",

  // Product
  GET_PRODUCT_BY_ID: "getProductById",

  // ReCaptcha
  VERIFY_CAPTCHA_SIGNATURE: "verifyCaptchaSignature",

  // CHALLENGES
  ACCEPT_CHALLENGE: "acceptChallenge",
  STOP_CHALLENGE: "stopChallenge",
  SWITCH_CHALLENGE: "switchChallenge",
  UNSUBSCRIBE_CHALLENGE: "unsubscribeChallenge",

  // INVOICES
  GET_INVOICES: "getInvoices",

  GET_USER: "getUser",

  REJECT_MEMBER_PROMO_OFFER: "rejectMemberPromoOffer",
  MEMBER_PROMO_VALIDATION: "memberPromoValidation",

  SEGMENT_EVENT_TRACKING_ERROR: "segmentEventTrackingError",
}
