// @flow
import * as React from "react"

import Popover from "../Popover"
import Icon from "../Icon"
type Props = {|
  userName: string,
  dropdownList: Array<React.Node>,
|}

const HeaderDropdown = ({ userName, dropdownList }: Props) => {
  const [isOpenDropdown, toggleDropdown] = React.useState(false)

  const renderDropdown = () => (
    <ul className="absolute inline-block profile-dropdown__item bg-gray-965 z-10 rounded-7 top-190/100 right-x-20">
      {dropdownList.map((item, index) => (
        <li key={"header-dropdown" + index}>{item}</li>
      ))}
    </ul>
  )

  return (
    <Popover
      togglePopover={toggleDropdown}
      className="profile-dropdown w-max-content h-max-content flex relative"
    >
      {userName && (
        <Icon
          icon="account"
          type="xl"
          classNames="w-7 bg-contain cursor-pointer ml-12"
          handleOnClick={() => toggleDropdown(!isOpenDropdown)}
        />
      )}
      {isOpenDropdown && renderDropdown()}
    </Popover>
  )
}

export default HeaderDropdown
