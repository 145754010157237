import React from "react"
import Spinner from "./Spinner"

type LoadingIndicatorProps = {|
  className?: string,
  testId?: string,
  children?: React.Node,
  isDarkTheme?: boolean,
|}

const LoadingIndicator = ({
  className = "",
  testId,
  isDarkTheme = false,
}: LoadingIndicatorProps) => (
  <div
    data-cy={testId}
    data-testid={testId}
    className={`w-full h-full absolute flex top-0 left-0 z-50 justify-center items-center text-white loading-indicator ${
      isDarkTheme ? "bg-gray-950" : "bg-white"
    } ${className}`}
  >
    <Spinner isDarkTheme={isDarkTheme} />
  </div>
)

export default LoadingIndicator
