import React, { memo } from "react"

type Props = {|
  handleHideToast?: () => void | Promise<void>,
  children: React.Node,
  type?: string,
  showHideIcon?: Boolean,
  classNames?: Object,
  testId?: string,
|}

const NotificationToast = ({
  handleHideToast,
  children,
  type,
  showHideIcon,
  classNames = {},
  testId = "",
}: Props) => (
  <div
    className={`py-20 h-auto pl-20 flex items-center justify-between ${type}-notification w-full${
      classNames.wrapper ? classNames.wrapper : ""
    }`}
    data-cy={testId}
    data-testid={testId}
    role="alert"
  >
    <div
      className={`${type}-notification font-base-medium leading-2relaxed md:leading-7none md:text-2lg text-xs text-left max-w-9/10"`}
    >
      {children}
    </div>
    {showHideIcon && (
      <div
        role="button"
        className="hide-toast relative w-6 h-6 cursor-pointer"
        onClick={handleHideToast}
      />
    )}
  </div>
)

export default memo(NotificationToast)
