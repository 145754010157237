import React from "react"
import Spinner from "../LoadingIndicator/Spinner"

type ButtonProps = {|
  handleOnClick?: () => void | Promise<void>,
  onMouseDown?: () => void | Promise<void>,
  innerRef?: () => void | Promise<void>,
  className?: string,
  disabled?: Boolean,
  submitting?: Boolean,
  children?: React.Node,
  type?: string,
|}

const Button = ({
  children,
  handleOnClick,
  disabled,
  submitting,
  className,
  type = "button",
  onMouseDown,
  innerRef = () => {},
}: ButtonProps) => (
  <button
    onClick={handleOnClick}
    className={
      disabled
        ? `${className} opacity-50 cursor-not-allowed`
        : `${className} focus:outline-none`
    }
    disabled={disabled}
    type={type}
    onMouseDown={onMouseDown}
    ref={innerRef}
  >
    {submitting ? <Spinner type="primary" /> : children}
  </button>
)

export default Button
