import * as React from "react"
import isEqual from "lodash/isEqual"

type Props = {|
  cell: {
    value?: String,
    customCell?: React.Node,
    classNames?: String,
  },
  classNames: String,
|}

const Cell = ({ cell, classNames }: Props) => (
  <div
    className={
      "leading-20 text-gray-800 font-base-light text-base " + classNames
    }
  >
    {cell.customCell || <p className={cell.classNames}>{cell.value}</p>}
  </div>
)

export default React.memo(Cell, (prevProps, nextProps) => {
  return isEqual(prevProps.cell, nextProps.cell)
})
