export const ERRORS = {
  NetworkError: "network_error",
  NotAuthorizedException: "not_authorized_exception",
  UserNotFoundException: "user_not_found_exception",
  ChangePassword: {
    NotAuthorizedException: "not_authorized_exception",
    InvalidPasswordException: "invalid_password_exception",
  },
  FailedRecaptchaV3: "failed recaptcha v3 verification",
}

export const PROMOTION_SIGNED_LINK_ERRORS = {
  UNKNOWN_PROMOTION_CODE: "promotion_signed_link_invalid",
  INVALID_SIGNATURE: "promotion_signed_link_invalid",
  LINK_EXPIRED: "promotion_signed_link_expired",
  LINK_REDEEMED: "promotion_signed_link_invalid",
  INVALID_COUNTRY: "promotion_signed_link_invalid_country",
  SIGNED_LINK_INVALID: "promotion_signed_link_invalid",
  INVALID_CUSTOMER_TYPE: "promotion_signed_link_invalid_customer_type",
}
