// @flow
import * as React from "react"
import FormTitle from "../FormTitle"
import LanguageDropdown from "../LanguageDropdown"
import { DEFAULT_LANGUAGE } from "../../constants/languages"

type FormWrapperProps = {|
  logo?: Object,
  title: string,
  children: React.Node,
  testId?: string,
  classNames?: Object,
  lang: string,
  loaded: Boolean,
  isLoggedIn: Boolean,
|}
const FormWrapper = ({
  logo = {},
  title,
  children,
  testId,
  classNames = {},
  loaded = false,
  lang = DEFAULT_LANGUAGE,
  isLoggedIn,
}: FormWrapperProps) => (
  <div className="form-wrapper bg-no-repeat flex overflow-auto md:items-center md:justify-center md:bg-cover">
    {loaded ? (
      <form
        className={
          classNames.wrapper
            ? `w-full text-center bg-white md:w-form-lg pt-53 md:pt-0 ${classNames.wrapper}`
            : `w-full text-center bg-white md:w-form-lg pt-53 md:pt-0`
        }
        data-cy={testId}
        autoComplete="off"
      >
        <div className="relative">
          <img
            src={logo.url}
            className="w-logo-lmod-base h-logo-lmod-base md:w-logo-lmod-lg md:h-logo-lmod-lg m-auto md:mt-74"
            alt="lesmills logo"
            data-cy="lesmills-logo"
          />
        </div>
        <FormTitle
          title={title}
          classNames="md:mt-28 mt-30 mr-auto ml-auto leading-2short"
        />
        <div className="form-content w-textbox-base md:w-textbox-lg mx-auto">
          <LanguageDropdown
            currentLang={lang}
            type="light"
            isLoggedIn={isLoggedIn}
          />
          {children}
        </div>
      </form>
    ) : null}
  </div>
)

export default React.memo(FormWrapper)
