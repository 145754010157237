import { PATTERNS as REGEX } from "../constants/patterns"

const PATTERNS = {
  EMAIL: REGEX.EMAIL,
  PASSWORD: REGEX.PASSWORD,
  CODE: REGEX.CODE,
}

export const FormValidator = (types, value, externalValues, confirmValue) => {
  let errorMsg = ""
  const noOfTypes = types.length

  for (let index = 0; index < noOfTypes; index++) {
    const type = types[index]
    const validation = PATTERNS[type]
    const { requiredError, invalidError, limitError } = externalValues

    switch (type) {
      case "REQUIRED":
        if (!value || value.length === 0) {
          errorMsg = requiredError
        }
        break

      case "EMAIL":
      case "CODE":
      case "PASSWORD":
        if (value && validation && !validation.test(value)) {
          errorMsg = invalidError
        }
        break

      case "CONFIRM_EMAIL":
        if (value && confirmValue && value !== confirmValue) {
          errorMsg = invalidError
        }
        break

      case "MEMBERSHIP_NUMBER":
        if (value.length > 20) {
          errorMsg = limitError
        }
        break

      default:
        break
    }
    if (errorMsg) {
      return errorMsg
    }
  }
  return errorMsg
}

export const checkHaveErrors = errors =>
  Object.keys(errors).some(key => errors[key])
