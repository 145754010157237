import React, { useState, useRef } from "react"
import Amplify, { Auth } from "aws-amplify"

import { ROUTES } from "../constants/routes"
import { cognitoConfig } from "../configs/cognito-config"
import type { PubicRouteProps } from "../types/RouteProps"
import { checkRedirectionPublicPage } from "../utils/redirection"
import { USER_INFO_KEY, getLocalStorage } from "../utils/localstorage"
import { getCurrentUser as getCurrentUserUtil } from "../utils/utilities"

import { withCheckEnableCountries } from "../components/CheckEnableCountriesHoc/index.js"

const PublicRoute = (props: PubicRouteProps) => {
  const currentUser = getLocalStorage(USER_INFO_KEY) || {}
  const { location, component: Component, ...rest } = props
  const isRedirectionPublicPage = checkRedirectionPublicPage(location.pathname)
  const [user, setUser] = useState(currentUser) // Fix re-render header when navigate
  const [checkingSession, setCheckingSession] = useState(
    !isRedirectionPublicPage
  )
  const isUnmount = useRef(false)

  const getCurrentUser = async () => {
    Amplify.configure(cognitoConfig)

    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(() => {
        if (!location.pathname.includes(ROUTES().SIGN_IN)) {
          setCheckingSession(false)

          getCurrentUserUtil(response => {
            if (!isUnmount.current) {
              setUser(response)
            }
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        setCheckingSession(false)
      })
  }

  React.useEffect(() => {
    // Only fetch current user for not redirection public page
    // Ticket: https://lesmillsinternational.atlassian.net/browse/LA-688
    if (!isRedirectionPublicPage) {
      getCurrentUser()
    }

    // This is used to avoid memory leak when unmount
    return () => {
      isUnmount.current = true
    }
  }, [])

  // Only return component after finish get user
  return <Component {...rest} user={user} checkingSession={checkingSession} />
}

export default withCheckEnableCountries(PublicRoute)
