import { getSessionStorage, CURRENT_COUNTRY } from "./localstorage"
import { AXII_CONFIGS } from "../../../../src/configs/axii-config"

export const orderProductsByPrice = (products = []) =>
  products.sort((a, b) =>
    a.product_price_point.price_in_cents < b.product_price_point.price_in_cents
      ? -1
      : 1
  )

export const getProductResponse = (data, isNZOffers) => {
  data = data.data || {}

  //https://lesmillsinternational.atlassian.net/browse/AB2B-905
  const res = isNZOffers ? data.getLmnzOfferV2 : data.getValidProducts

  return res
    ? isNZOffers
      ? {
          products: [
            ...res.map(item => ({
              ...item,
              currency:
                item.product_price_point && item.product_price_point.currency,
            })),
          ],
          countryCode: res[0].country_code,
        }
      : res
    : {
        products: [],
        countryCode: "",
      }
}

export const isMonthlyProduct = product => {
  const product_price_point = product.product_price_point || {}

  return (
    product_price_point.interval_unit === "month" &&
    product_price_point.interval === 1
  )
}

export const isAnnualProduct = product => {
  const product_price_point = product.product_price_point || {}

  return (
    product_price_point.interval_unit === "month" &&
    product_price_point.interval === 12
  )
}

export const isTrialProduct = product => {
  const product_price_point = product.product_price_point || {}

  return product_price_point.trial_interval
}

export const getDefaultTrialProduct = products => {
  const monthlyTrialProduct =
    products.find(
      product => isMonthlyProduct(product) && isTrialProduct(product)
    ) || null

  if (!monthlyTrialProduct) {
    return (
      products.find(
        product => isAnnualProduct(product) && isTrialProduct(product)
      ) || null
    )
  }

  return monthlyTrialProduct
}

export const convertTrialPeriodToDays = product => {
  if (!isTrialProduct(product)) {
    return
  }

  const { trial_interval_unit, trial_interval } = product.product_price_point

  switch (trial_interval_unit) {
    case "day":
      return trial_interval
    case "month":
      return trial_interval * 30
    case "year":
      return trial_interval * 365
  }
}

export const getShortestTrialProduct = products => {
  let shortestTrialIndex
  shortestTrialIndex = products.findIndex(product => isTrialProduct(product))

  products.forEach((item, index) => {
    if (
      isTrialProduct(item) &&
      convertTrialPeriodToDays(item) <
        convertTrialPeriodToDays(products[shortestTrialIndex])
    ) {
      shortestTrialIndex = index
    }
  })
  return products[shortestTrialIndex]
}

export const getNextBillingDate = (currentPeriodEndDate = "") => {
  let result = new Date(currentPeriodEndDate)
  result.setDate(result.getDate() + 1)
  return result
}

export const getTrialEndDate = (trial_interval, trial_interval_unit) => {
  if (!trial_interval || !trial_interval_unit) {
    return ""
  }
  const now = new Date(Date.now())
  let trialEndDate = 0

  switch (trial_interval_unit) {
    case "month":
      trialEndDate = now.setMonth(now.getMonth() + trial_interval)
      break

    case "year":
      trialEndDate = now.setFullYear(now.getFullYear() + trial_interval)
      break

    default:
      trialEndDate = now.setDate(now.getDate() + trial_interval)
      break
  }

  return trialEndDate
}

// https://lesmillsinternational.atlassian.net/browse/LA-1444
// Display price in /membership as a "per month" format in the US
export const formatPricePerMonth = ({
  price_in_cents,
  interval_unit,
  interval,
} = {}) => {
  // if subscription is daily, price per month is price of 1 day * 30
  if (interval_unit === "day") {
    return price_in_cents * 30
  }

  // others subscription types have interval_unit is month
  // so price per month is price / interval (number of months)
  return price_in_cents / interval
}

export const needFormatPricePerMonth = isNZOffers => {
  return (
    AXII_CONFIGS.pricePerMonthFormatCountries.includes(
      getSessionStorage(CURRENT_COUNTRY).toLowerCase()
    ) && !isNZOffers
  )
}

export const getPlanPeriod = (interval, intervalUnit) => {
  if (interval >= 12 && intervalUnit.toLowerCase() === "month") {
    return "1 year"
  }

  const unit = interval > 1 ? intervalUnit : `${intervalUnit}s`
  return `${interval} ${unit}`
}

export const getConvertedPrice = (priceInCents, currency, toFixedNumber) => {
  let convertedPrice = priceInCents / 100

  if (currency === "JPY") {
    convertedPrice = priceInCents
  } else if (toFixedNumber) {
    convertedPrice = (priceInCents / 100).toFixed(toFixedNumber)
  }

  return convertedPrice
}

export const getConvertedPlanPeriod = (interval, intervalUnit) => {
  let period = "monthly"
  if (interval >= 12 && intervalUnit.toLowerCase() === "month") {
    period = "annually"
  }
  if (interval == 3 && intervalUnit.toLowerCase() === "month") {
    period = "3-monthly"
  }
  return period
}
