import { AnalyticsBrowser } from "@segment/analytics-next"
import { SENTRY_ACTIONS, isBrowser } from "@lesmills/gatsby-theme-common"
import { captureException } from "./sentry"

const EVENT_NAME = {
  OFFER_REJECTED: "Offer Rejected",
  CANCEL_SUBSCRIPTION: "Cancel Subscription Clicked",
}

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.GATSBY_SEGMENT_AXII_WRITE_KEY,
})

export function identifyEvent(userId) {
  if (analytics && userId) {
    analytics.identify(userId)
    return true
  }

  return false
}

export async function trackEvent(traits, value) {
  try {
    if (isBrowser && analytics) {
      await analytics.track(traits.event_name, value)
    }
  } catch (error) {
    captureException({
      action: SENTRY_ACTIONS.SEGMENT_EVENT_TRACKING_ERROR,
      error,
    })
  }
}

export async function trackRejectOffer() {
  await trackEvent(
    { event_name: EVENT_NAME.OFFER_REJECTED },
    { step_label: "Proceed with cancelation" }
  )
}

export async function trackCancelSubscription() {
  await trackEvent({ event_name: EVENT_NAME.CANCEL_SUBSCRIPTION })
}
