import * as React from "react"
import isEqual from "lodash/isEqual"

import Row from "./Row"

type Props = {|
  headers: Array<String>,
  rows: Array<Object>,
  rowClassNames: String,
|}

const Body = ({ headers, rows, rowClassNames = "" }: Props) => {
  return (
    <>
      {rows.map((row, idx) => {
        return (
          <Row
            headers={headers}
            classNames={rowClassNames}
            value={row}
            key={"row-" + idx}
          />
        )
      })}
    </>
  )
}

export default React.memo(Body, (prevProps, nextProps) => {
  return isEqual(prevProps.rows, nextProps.rows)
})
