export const UpdateUserCart = `
  mutation updateAxiiUser($input: AxiiUserInput!) {
    updateAxiiUser(input: $input) {
      email
      cartProductHandle
      cartOfferId
      cartAffiliateId
      cartExpiryDate
      cartTransactionId
    }
  }
`
