import React from "react"

type Props = {|
  handleDismiss: () => void,
  video: string,
|}

const MediaModal = ({ handleDismiss, video }: Props) => (
  <div
    className="top-0 left-0 w-full h-full fixed z-100 bg-modal-base"
    onClick={handleDismiss}
    data-cy="media-modal"
  >
    <div className="modal-content top-1/2 left-1/2 max-w-95/100 h-video-modal relative md:w-video-lg">
      <div
        className="modal-close opacity-75 w-close-icon h-close-icon absolute cursor-pointer w-close-icon top-x-45 right-0"
        onClick={handleDismiss}
      />
      <iframe
        width="100%"
        height="100%"
        src={video}
        frameBorder="0"
        title="mediaModal"
      />
    </div>
  </div>
)

export default MediaModal
